import { useCallback, useContext, useEffect, useState } from "react";
import { SnackbarContext } from "../../../utils/Contexts";
import { LooseObject, PkgName, QueryResultTable, QueryType } from "../../../utils/Types";
import { selectUser } from "../../../redux/reducers/userSlice";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { selectOrgs } from "../../../redux/reducers/orgsSlice";
import _ from "lodash";
import { getCameraOwnerStringFromOrgIdString, getCurrentOrg, getDataFromDataPool, prepareQueryResultForTable } from "../../../utils/Helper";
import { FORMATTED } from "../../../utils/Constants";
import { Center, Chip, CopyButton, Dialog, ImageDetails, SmallButton, Spinner, Table } from "../../../components";
import { Box, Stack, Typography, useTheme } from "@mui/material";
import { MRT_GlobalFilterTextField, MRT_Row, MRT_RowData, MRT_ShowHideColumnsButton, MRT_ToggleFullScreenButton } from "material-react-table";
import { selectDataPool } from "../../../redux/reducers/dataPoolSlice";
import AllImages, { hiddenColumnNames, imgUrlKey } from "./AllImages";
import { selectZitadelOrg } from "../../../redux/reducers/zitadelOrgSlice";
import Grid from "@mui/material/Unstable_Grid2/Grid2";

const Page = ({ row }: { row: MRT_Row<MRT_RowData> }) => {
  const snackbar = useContext(SnackbarContext);
  const [loading, setLoading] = useState(false);

  const [data, setData] = useState<QueryResultTable>();
  const [tableRows, setTableRows] = useState<MRT_Row<any>[]>();
  const [width, setWidth] = useState();
  const [openForDialogAllImages, setOpenForDialogAllImages] = useState(false);
  const [openForDialogImageDetails, setOpenForDialogImageDetails] = useState(false);
  const [currentItem, setCurrentItem] = useState<LooseObject>({});

  const user = useAppSelector(selectUser);
  const orgs = useAppSelector(selectOrgs);
  const zitadelOrg = useAppSelector(selectZitadelOrg);
  const dataPool = useAppSelector(selectDataPool);
  const dispatch = useAppDispatch();

  const theme = useTheme();

  useEffect(() => {
    fetchData();

    // Cleanup or clear any pending debounced function calls if necessary
    return () => fetchData.cancel();
  }, []);

  const fetchData = _.debounce(async () => {
    setLoading(true);
    await getDataFromDataPool({
      dataPool,
      params: {
        id: getCurrentOrg(orgs)?.idString,
        type: QueryType.ORDINARY_QUERY,
        view: `MEQCAMERA_INDIVIDUAL_IMAGES('${row.original.PHOTO_DATE}', '${row.original.PHOTO_DATE}', '${getCameraOwnerStringFromOrgIdString(getCurrentOrg(orgs)?.idString)}')`,
        isFunction: true,
        pkg: PkgName.MEQ_CAMERA,
      },
      token: user.access_token,
      dispatch,
      zitadelOrg,
      snackbar,
    }).then(dataFromDataPool => {
      setData(prepareQueryResultForTable({ data: dataFromDataPool, org: getCurrentOrg(orgs), hiddenColumnNames: ["AZ_URL"], columnParams: { PHOTO_DATETIME: { maxSize: 250 } } }));
    });
    setLoading(false);
  }, 500);

  const stackRef = useCallback((node: any) => {
    if (node !== null) {
      setWidth(node.getBoundingClientRect().width);
    }
  }, []);

  const setTableRowsWithDebounce = _.debounce(async (flatRows: MRT_Row<any>[]) => {
    if (flatRows) {
      setTableRows(flatRows.map(i => i.original));
    }
  }, 500);

  return (
    <Stack ref={stackRef}>
      <Stack spacing={3} width={width === undefined ? "auto" : width}>
        {!data?.tableColumns ? (
          <Spinner />
        ) : (
          <Table
            loading={loading}
            title={`List of images ${row.original["PHOTO_DATE_" + FORMATTED]}`}
            columns={data?.tableColumns || []}
            data={data?.tableRows || []}
            renderTopToolbar={({ table }) => {
              return (
                <Box sx={{ display: "flex", gap: 1, p: 2, justifyContent: "space-between", alignItems: "center" }}>
                  <Typography variant="textmd" fontWeight="semiBold">
                    {`List of images ${row.original["PHOTO_DATE_" + FORMATTED]}`}
                  </Typography>
                  <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
                    <SmallButton title="View All Images" variant="contained" onClick={() => setOpenForDialogAllImages(true)} />
                    <MRT_GlobalFilterTextField table={table} />
                    <Box>
                      <MRT_ShowHideColumnsButton table={table} />
                      <MRT_ToggleFullScreenButton table={table} />
                    </Box>
                  </Box>
                </Box>
              );
            }}
            muiTableBodySx={{
              '& tr:nth-of-type(4n+3):not([data-selected="true"]):not([data-pinned="true"]) > td': { backgroundColor: `${theme.palette.background.default} !important` },
            }}
            muiTableBodyRowProps={({ row }) => ({
              onClick: () => {
                setCurrentItem(row.original);
                setOpenForDialogImageDetails(true);
              },
              sx: {
                cursor: "pointer",
              },
            })}
            exposeRowModel={faltRows => setTableRowsWithDebounce(faltRows)}
            enableRowNumbers
            initialState={{ sorting: [{ id: "PHOTO_DATETIME_FORMATTED", desc: true }] }}
          />
        )}
        <Dialog open={openForDialogAllImages} onClose={() => setOpenForDialogAllImages(false)} isTransparent fullWidth>
          <AllImages row={row} data={data} tableRows={tableRows || []} onCancel={() => setOpenForDialogAllImages(false)} />
        </Dialog>
        <Dialog open={openForDialogImageDetails} onClose={() => setOpenForDialogImageDetails(false)} isTransparent>
          <ImageDetails
            initialItem={currentItem}
            columns={data?.tableColumns || []}
            idKey="BARCODE"
            imgUrlKey={imgUrlKey}
            rows={tableRows || []}
            hiddenColumnNames={hiddenColumnNames}
            header={image => (
              <Stack>
                <Grid container spacing={1}>
                  <Grid>
                    <Chip label={image?.PHOTO_DATETIME_FORMATTED} />
                  </Grid>
                  <Grid>
                    <Center direction="row" spacing={1}>
                      <Chip label={image?.BARCODE} />
                      <CopyButton text={image?.BARCODE} color={theme.palette.text.secondary} size={18} sx={{ width: 23, height: 23, pt: 2 }} title="Copy Barcode" />
                    </Center>
                  </Grid>
                </Grid>
              </Stack>
            )}
            onCancel={() => setOpenForDialogImageDetails(false)}
          />
        </Dialog>
      </Stack>
    </Stack>
  );
};

export default Page;
