import { Button, Center, ContentWrapper, Dialog, PublicPageContainer } from "../../../components";
import { User } from "oidc-client-ts";
import { useContext, useEffect, useState } from "react";
import { Backdrop, CircularProgress, Divider, Typography, useTheme } from "@mui/material";
import SelectOrg from "./SelectOrg";
import {
  actAfterLoginSuccess,
  getLocationFromOrgIdString,
  getOrgFromOrgIdString,
  getRolesFromUser,
  loadUserPermission,
  logout,
  postToServer,
  isNotEmpty,
  getZitadelFromZitadelOrg,
  getOrgFromHostname,
  isDarkMode,
} from "../../../utils/Helper";
import { SnackbarContext } from "../../../utils/Contexts";
import { MessageText, Org, ZitadelAuth, ZitadelOrg, Message, LooseObject } from "../../../utils/Types";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { ALL_LOCATIONS, SUPER_ADMIN, SUPER_USER } from "../../../utils/Constants";
import _ from "lodash";
import { selectZitadelOrg, setZitadelOrg as setZitadelOrgRedux } from "../../../redux/reducers/zitadelOrgSlice";
import MeqLogoLight from "../../../assets/images/meq-logo-light.png";
import MeqLogoDark from "../../../assets/images/meq-logo-dark.png";
import AllianceLogoLight from "../../../assets/images/alliance-logo-light.png";
import AllianceLogoDark from "../../../assets/images/alliance-logo-dark.png";
import PoweredByMeq from "../../../assets/images/powered-by-meq.png";

const Page = ({ isCallback }: { isCallback?: boolean }) => {
  const snackbar = useContext(SnackbarContext);
  const [loading, setLoading] = useState(false);
  const [loadingV1, setLoadingV1] = useState(false);
  const [initializingCallback, setInitializingCallback] = useState(false);

  const [authenticatedByZitadel, setAuthenticatedByZitadel] = useState<boolean>();
  const [userFromZitadel, setUserFromZitadel] = useState<User>();
  const [openDialogSelectOrg, setOpenDialogSelectOrg] = useState(false);
  const [orgs, setOrgs] = useState<Org[]>([]);
  const [zitadel, setZitadel] = useState<ZitadelAuth>();
  const [zitadelOrg, setZitadelOrg] = useState<ZitadelOrg>();

  const [orgsV1, setOrgsV1] = useState<Org[]>([]);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const theme = useTheme();

  let zitadelOrgRedux = useAppSelector(selectZitadelOrg);

  const userManagerFromZitadel = zitadel?.userManager;

  useEffect(() => {
    if (!zitadel) {
      setZitadelOrg(zitadelOrgRedux);
      loadZitadel();
    }
  }, [zitadelOrg]);

  useEffect(() => {
    if (isCallback) {
      setInitializingCallback(true);
    }
  }, []);

  useEffect(() => {
    if (userManagerFromZitadel) {
      userManagerFromZitadel.getUser().then(user => {
        // check token expired or not
        if (user && (user.expires_at || 0) * 1000 > Date.now()) {
          setAuthenticatedByZitadel(true);
          setUserFromZitadel(user);
        } else {
          setAuthenticatedByZitadel(false);
        }
      });
    }
  }, [userManagerFromZitadel]);

  useEffect(() => {
    if (userManagerFromZitadel) {
      if (authenticatedByZitadel === false) {
        userManagerFromZitadel
          .signinRedirectCallback()
          .then((user: User) => {
            if (user) {
              setAuthenticatedByZitadel(true);
              setUserFromZitadel(user);
            } else {
              setAuthenticatedByZitadel(false);
            }
          })
          .catch(err => {
            setAuthenticatedByZitadel(false);

            if (process.env.REACT_APP_ZITADEL_REDIRECT_URI && err.message?.includes("No matching state found in storage")) {
              window.location.href = `${process.env.REACT_APP_ZITADEL_REDIRECT_URI}`;
            }
          });
      }
    }
  }, [authenticatedByZitadel]);

  useEffect(() => {
    loadUserOrgsV1();
  }, [userFromZitadel]);

  useEffect(() => {
    loadUserOrgs();
  }, [orgsV1]);

  const loadZitadel = async () => {
    let zitadelOrgToLoadZitadel: ZitadelOrg = zitadelOrg;

    // if no zitadelOrg in redux
    if (!isNotEmpty(zitadelOrgToLoadZitadel) || process.env.REACT_APP_ZITADEL_ORG !== zitadelOrgToLoadZitadel?.idString) {
      let orgString = process.env.REACT_APP_ZITADEL_ORG || getOrgFromHostname(window.location.hostname);

      if (orgString) {
        setLoading(true);
        const params = { idString: _.startCase(orgString) };

        await postToServer({ action: "GetZitadelOrg", params, token: "", zitadelOrgIdString: "" }).then(async response => {
          setLoading(false);
          if (response.message.type === "success" && response.serverData) {
            const zitadelOrgToLoadZitadel = response.serverData as ZitadelOrg;
            setZitadelOrg(zitadelOrgToLoadZitadel);

            // save orgs to current browser
            localStorage.setItem("zitadelOrg", JSON.stringify(zitadelOrgToLoadZitadel));
            dispatch(
              setZitadelOrgRedux({
                type: "zitadelOrg/set",
                payload: zitadelOrgToLoadZitadel,
              })
            );
          } else {
            snackbar.open(response.message);
            setAuthenticatedByZitadel(false);
          }
        });
      } else {
        snackbar.open({ text: MessageText.PAGE_NOT_FOUND, type: "error" } as Message);
        setAuthenticatedByZitadel(false);
      }
    }

    if (isNotEmpty(zitadelOrgToLoadZitadel)) {
      setZitadel(getZitadelFromZitadelOrg(zitadelOrgToLoadZitadel));
    }
  };

  const loadUserOrgs = async () => {
    if (userFromZitadel && zitadelOrg) {
      setLoading(true);
      await postToServer({ action: "UserOrgs", params: {}, token: userFromZitadel.access_token, zitadelOrgIdString: zitadelOrg.idString }).then(async response => {
        if (response.statusCode === 401) {
          logout({ dispatch, zitadelOrg });
        } else {
          if (response.message.type === "success" && response.serverData) {
            const orgsFromServer = response.serverData as Org[];
            setOrgs(orgsFromServer);

            if (orgsFromServer.length === 0) {
              // no orgs
              setLoading(false);
              // snackbar.open({ text: MessageText.NO_ORG_FOUND, type: "error" });
            } else if (orgsFromServer.length === 1 && orgsV1.length === 0) {
              // enter to main page automatically
              const orgsWithCurrent: Org[] = [{ ...orgsFromServer[0]!, isCurrent: true }];
              setOrgs(orgsWithCurrent);

              const roles = await getRolesFromUser(userFromZitadel, zitadelOrg.idString);
              const permission = roles.includes(SUPER_ADMIN) || roles.includes(SUPER_USER) ? undefined : await loadUserPermission(userFromZitadel, roles[0], zitadelOrg.idString); // login as first available role
              const userConfig = await postToServer({ action: "UserConfig", params: {}, token: userFromZitadel.access_token, zitadelOrgIdString: zitadelOrg.idString }).then(
                response => response.serverData as LooseObject
              );

              actAfterLoginSuccess({ orgs: orgsWithCurrent, user: userFromZitadel, allRoles: roles, permission, userConfig, dispatch, navigate });
            } else {
              // setlect org
              setLoading(false);
              setOpenDialogSelectOrg(true);
            }
          } else {
            setLoading(false);
            snackbar.open(response.message);
          }
        }
      });
      setInitializingCallback(false);
    }
  };

  const loadUserOrgsV1 = async () => {
    if (userFromZitadel && zitadelOrg) {
      setLoadingV1(true);
      await postToServer({ action: "UserOrgsFromV1", params: {}, token: userFromZitadel.access_token, zitadelOrgIdString: zitadelOrg.idString }).then(async response => {
        if (response.message.type === "success" && response.serverData) {
          const orgsFromServer = response.serverData as Org[];
          setOrgsV1(orgsFromServer);

          if (orgsFromServer.length > 0) {
            setOpenDialogSelectOrg(true);
          }
        } else {
          snackbar.open(response.message);
        }
      });
      setLoadingV1(false);
      setInitializingCallback(false);
    }
  };

  const selectOrg = async (orgIdString?: string) => {
    if (orgIdString && userFromZitadel) {
      const orgsWithCurrent: Org[] = orgs.map(i => (i!.idString === orgIdString ? { ...i!, isCurrent: true } : { ...i!, isCurrent: false }));
      setOrgs(orgsWithCurrent);
      const allRoles = await getRolesFromUser(userFromZitadel, zitadelOrg?.idString);

      if (allRoles) {
        // if super admin has other roles, only show super admin role
        const roles = allRoles.includes(SUPER_ADMIN)
          ? [SUPER_ADMIN]
          : allRoles.includes(SUPER_USER)
          ? [SUPER_USER]
          : allRoles.filter((role: string) => role.startsWith(orgIdString) || role.startsWith(getOrgFromOrgIdString(orgIdString) + "_" + ALL_LOCATIONS));

        const roleToLoadPermission =
          roles[0] && roles[0] !== SUPER_ADMIN && roles[0] !== SUPER_USER && !roles[0].startsWith(orgIdString)
            ? roles[0].replace(ALL_LOCATIONS, getLocationFromOrgIdString(orgIdString))
            : roles[0]; // same short role when All_Location user access single location

        const permission =
          roles.includes(SUPER_ADMIN) || roles.includes(SUPER_USER) ? undefined : await loadUserPermission(userFromZitadel!, roleToLoadPermission, zitadelOrg?.idString); // login as first available role

        const userConfig = await postToServer({ action: "UserConfig", params: {}, token: userFromZitadel.access_token, zitadelOrgIdString: zitadelOrg?.idString }).then(
          response => response.serverData as LooseObject
        );
        actAfterLoginSuccess({ orgs: orgsWithCurrent, user: userFromZitadel, allRoles, permission, userConfig, dispatch, navigate });

        setOpenDialogSelectOrg(false);
      }
    }
  };

  return (
    <PublicPageContainer bgcolor="mybg.secondary">
      <Center>
        {zitadelOrg?.idString === "Alliance" && (
          <Center mb={3}>
            <img src={PoweredByMeq} alt="logo" width={84} height={50} />
          </Center>
        )}
        <ContentWrapper maxWidth={400}>
          <Center p={1} spacing={2}>
            <Center py={4}>
              {zitadelOrg?.idString === "Alliance" ? (
                <img src={isDarkMode(theme) ? AllianceLogoDark : AllianceLogoLight} alt="logo" width={246} />
              ) : (
                <img src={isDarkMode(theme) ? MeqLogoDark : MeqLogoLight} alt="logo" width={246} />
              )}
            </Center>
            <Divider sx={{ width: "100%" }} />
            <Typography>Welcome back!</Typography>
            <Button
              variant="contained"
              title={userFromZitadel ? "Logout" : "Login"}
              onClick={() => (userFromZitadel ? zitadel?.signout() : zitadel?.authorize())}
              disabled={(isCallback && initializingCallback) || loading || loadingV1 || !isNotEmpty(zitadelOrg)}
              sx={{ width: "100%" }}
            />
          </Center>
          {isCallback && initializingCallback && !loading && !loadingV1 && userFromZitadel && orgs && orgs.length === 0 && orgsV1 && orgsV1.length === 0 && (
            <Typography variant="textsm" sx={theme => ({ my: 3, color: theme.palette.utility.error[500] })} textAlign="center">
              {MessageText.NO_ORG_FOUND}
            </Typography>
          )}
        </ContentWrapper>
      </Center>
      <Dialog open={openDialogSelectOrg} onClose={() => setOpenDialogSelectOrg(false)} fullScreen>
        <SelectOrg user={userFromZitadel} orgs={orgs} orgsV1={orgsV1} onSelectOrg={selectOrg} closeDialog={() => setOpenDialogSelectOrg(false)} />
      </Dialog>
      <Backdrop open={(isCallback && (initializingCallback || loading || loadingV1)) || authenticatedByZitadel === undefined || loading || loadingV1}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </PublicPageContainer>
  );
};

export default Page;
