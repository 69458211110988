import { Stack, Tab, Tabs } from "@mui/material";
import { useState } from "react";
import LambDashboard from "./Dashboard";
import BeefDashboard from "../../BeefDataAnalysis/Supplier/Dashboard";
import { useAppSelector } from "../../../redux/hooks";
import { selectUserConfig } from "../../../redux/reducers/userConfigSlice";
import NoDataView from "components/NoDataView";
import { ALLIANCE_ALL_LOCATIONS, SUPER_ADMIN, SUPER_USER } from "utils/Constants";
import { selectRoles } from "../../../redux/reducers/rolesSlice";

const Page = () => {
  const [tab, setTab] = useState(0);

  const userConfig = useAppSelector(selectUserConfig);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTab(newValue);
  };

  const roles = useAppSelector(selectRoles);
  const currentRole = roles.find(i => i?.isCurrent);
  const isSuperUsers = currentRole && [SUPER_ADMIN, SUPER_USER].includes(currentRole.role);
  const isAllLocationUser = currentRole?.role.split("-")[0] === ALLIANCE_ALL_LOCATIONS + "-User";

  const isAllowedToSeeAllLocations = isSuperUsers || isAllLocationUser;

  if (!isAllowedToSeeAllLocations && !userConfig.allianceLambViewAllowed && !userConfig.allianceBeefViewAllowed) {
    return <NoDataView />;
  }

  return (
    <Stack spacing={3} flex={1}>
      <Stack direction="row" justifyContent="space-between">
        <Tabs value={tab} onChange={handleChange}>
          {(isAllowedToSeeAllLocations || userConfig.allianceLambViewAllowed) && <Tab label="Supplier Lamb Dashboard" />}
          {(isAllowedToSeeAllLocations || userConfig.allianceBeefViewAllowed) && <Tab label="Supplier Beef Dashboard" />}
        </Tabs>
      </Stack>
      {tab === 0 && (isAllowedToSeeAllLocations || userConfig.allianceLambViewAllowed) ? <LambDashboard /> : <BeefDashboard />}
    </Stack>
  );
};

export default Page;
