import { isNotEmpty } from "../../../../../utils/Helper";
import { LooseObject, QueryResult } from "../../../../../utils/Types";

export type GroupedData = {
  [key: string]: {
    items: LooseObject[];
    total: number;
    percentage: number;
  };
};

export const groupDataByVsGradeForTable = (data: LooseObject[], categories: string[]): GroupedData => {
  const totalItems = data.length;
  const groupedData: GroupedData = {};

  // Initialize the groupedData with empty arrays
  categories.forEach(category => {
    groupedData[category] = {
      items: [],
      total: 0,
      percentage: 0,
    };
  });

  // Group the data by the specified categories
  data.forEach(item => {
    const category = categories.find(i => {
      let flag = false;
      switch (i) {
        case "Y1":
        case "Y2":
          flag = item.VS_GRADE === i;
          break;
        case "P":
          flag = item.VS_GRADE === "P1" || item.VS_GRADE === "P2";
          break;
        case "T & F":
          flag = item.VS_GRADE === "T" || item.VS_GRADE === "F";
          break;
        case "Other":
          flag = item.VS_GRADE !== "Y1" && item.VS_GRADE !== "Y2" && item.VS_GRADE !== "P1" && item.VS_GRADE !== "P2" && item.VS_GRADE !== "T" && item.VS_GRADE !== "F";
          break;
      }
      return flag;
    });
    if (category) {
      groupedData[category].items.push(item);
      groupedData[category].total += 1;
    }
  });

  // Calculate the percentage for each group
  categories.forEach(category => {
    if (groupedData[category].total > 0) {
      groupedData[category].percentage = (groupedData[category].total / totalItems) * 100;
    }
  });

  return groupedData;
};

export const groupDataByVsGradeForChart = (data: LooseObject[] | undefined, categories: string[]) =>
  categories.map(category => ({
    data:
      data?.filter(i => {
        let flag = false;
        switch (category) {
          case "Y1":
          case "Y2":
            flag = i.VS_GRADE === category;
            break;
          case "P":
            flag = i.VS_GRADE === "P1" || i.VS_GRADE === "P2";
            break;
          case "T, F & Others":
            flag = i.VS_GRADE !== "Y1" && i.VS_GRADE !== "Y2" && i.VS_GRADE !== "P1" && i.VS_GRADE !== "P2";
            break;
        }
        return flag;
      }) || [],
    name: category,
    markerSymbol: "circle",
  }));

export const avarageYield = ({ title, data }: { data: QueryResult | undefined; title: "Leg Meat Yield" | "Loin Meat Yield" | "Shoulder Meat Yield" }) => {
  let avarage = 0;
  switch (title) {
    case "Leg Meat Yield":
      const legMeatYield = data?.rows?.filter(i => isNotEmpty(i.LEG_YIELD));
      if (legMeatYield && legMeatYield.length > 0) {
        avarage = legMeatYield.reduce((acc, curr) => acc + curr.LEG_YIELD, 0) / legMeatYield.length;
      }
      break;
    case "Loin Meat Yield":
      const loinMeatYield = data?.rows?.filter(i => isNotEmpty(i.LOIN_YIELD));
      if (loinMeatYield && loinMeatYield.length > 0) {
        avarage = loinMeatYield.reduce((acc, curr) => acc + curr.LOIN_YIELD, 0) / loinMeatYield.length;
      }
      break;
    case "Shoulder Meat Yield":
      const shoulderMeatYield = data?.rows?.filter(i => isNotEmpty(i.SHLD_YIELD));
      if (shoulderMeatYield && shoulderMeatYield.length > 0) {
        avarage = shoulderMeatYield.reduce((acc, curr) => acc + curr.SHLD_YIELD, 0) / shoulderMeatYield.length;
      }
  }
  return avarage.toFixed(2);
};
