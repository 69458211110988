import { Stack, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { Center, Chip, CircularProgressIndicator, NoImagePlaceholder, Tile, TileContentText } from "../../../../components";
import { DateFormat, DateFormatServer, LooseObject } from "../../../../utils/Types";
import { isNotEmpty } from "../../../../utils/Helper";
import { imgUrlKey } from "./AllImages";
import moment from "moment";

const Row = ({ label, value }: { label: string; value: any }) => (
  <Stack spacing={2} direction="row" justifyContent="space-between" sx={{ borderBottom: theme => `1px solid ${theme.palette.myborder.secondary}` }}>
    <Typography variant="textsm">{label}</Typography>
    <Typography variant="textsm">{value}</Typography>
  </Stack>
);

const RankedImage = ({ title, carcase }: { title: string; carcase: LooseObject }) => (
  <Stack spacing={1}>
    <Stack direction="row" spacing={2} alignItems="center">
      <Typography variant="textsm" fontWeight="semiBold">
        {title}
      </Typography>
      <Stack direction="row" spacing={1}>
        <Chip label={`${moment(carcase.PHOTO_DATETIME, DateFormatServer.SHORT).format(DateFormat.SHORT)}`} size="small" />
      </Stack>
    </Stack>
    <Grid container spacing={2}>
      <Grid xs={12} sm={6} md={3} lg>
        <Tile
          content={
            <Center style={{ width: "100%", height: "125px", overflow: "hidden" }}>
              {carcase[imgUrlKey] ? (
                <img
                  onDragStart={(e: any) => e.preventDefault()} // prevent image drag
                  src={"https://" + carcase[imgUrlKey] + process.env.REACT_APP_IMAGE_SAS_TOKEN}
                  alt="item"
                  loading="lazy"
                  style={{ width: "100%", height: "100%", objectFit: "cover" }}
                />
              ) : (
                <NoImagePlaceholder />
              )}
            </Center>
          }
          contentSx={{ p: 0, ":last-child": { pb: 0 } }}
          minWidth={200}
        />
      </Grid>
      <Grid xs={12} sm={6} md={3} lg>
        <Tile
          title="IMF"
          content={
            <Stack spacing={2} direction="row">
              <TileContentText text={isNotEmpty(carcase.IMF) ? `${carcase.IMF}%` : "N/A"} />
              <CircularProgressIndicator value={carcase.IMF ? carcase.IMF : 0} maxValue={10} size={40} borderWidth={10} />
            </Stack>
          }
          minWidth={200}
        />
      </Grid>
      <Grid xs={12} sm={6} md={3} lg>
        <Tile
          title="MSA Marbling"
          content={
            <Stack spacing={2} direction="row">
              <TileContentText text={isNotEmpty(carcase.MSA_MARBLING) ? carcase.MSA_MARBLING : "N/A"} />
            </Stack>
          }
          minWidth={200}
        />
      </Grid>
      <Grid xs={12} sm={6} md={3} lg>
        <Tile
          title="Eye Muscle Area"
          content={
            <Stack spacing={2} direction="row">
              <TileContentText text={isNotEmpty(carcase.EYE_MUSCLE_AREA) ? `${carcase.EYE_MUSCLE_AREA} cm2` : "N/A"} />
              <CircularProgressIndicator value={carcase.EYE_MUSCLE_AREA} maxValue={100} size={40} borderWidth={10} />
            </Stack>
          }
          minWidth={200}
        />
      </Grid>
      <Grid xs={12} sm={6} md={3} lg>
        <Tile
          content={
            <Stack spacing={2} style={{ width: "100%", height: "107px", overflow: "hidden" }}>
              <Row label="AUS Marbling" value={carcase.AUS_MARBLING || "N/A"} />
              <Row label="Fat Color" value={carcase.FAT_COLOR || "N/A"} />
              <Row label="Meat Color" value={carcase.MEAT_COLOR || "N/A"} />
            </Stack>
          }
          contentSx={{ p: 2, ":last-child": { pb: 0 } }}
          minWidth={200}
        />
      </Grid>
    </Grid>
  </Stack>
);

export default RankedImage;
