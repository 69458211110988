import { Stack, Tab, Tabs } from "@mui/material";
import { useState } from "react";
import LambDashboard from "./Dashboard";
import BeefDashboard from "../BeefDataAnalysis/Dashboard";
import { useAppSelector } from "../../redux/hooks";
import Supplier from "./Supplier";
import { selectIsAdminView } from "../../redux/reducers/isAdminViewSlice";
import { selectRoles } from "../../redux/reducers/rolesSlice";

export const SUPPLIER = "Supplier";

enum AdminPages {
  LambDashboard,
  BeefDashboard,
}

const Page = () => {
  const [tab, setTab] = useState<AdminPages>(AdminPages.LambDashboard);

  const handleChange = (_: React.SyntheticEvent, newValue: number) => {
    setTab(newValue);
  };

  const isAdminView = useAppSelector(selectIsAdminView);

  const roles = useAppSelector(selectRoles);
  const currentRole = roles.find(i => i?.isCurrent);

  // should show the view to admin and user (non-supplier)
  if (isAdminView || currentRole?.role.includes("-User")) {
    return (
      <Stack spacing={3} flex={1}>
        <Stack direction="row" justifyContent="space-between">
          <Tabs value={tab} onChange={handleChange}>
            <Tab label="Lamb Dashboard" />
            <Tab label="Beef Dashboard" />
          </Tabs>
        </Stack>
        {tab === AdminPages.LambDashboard ? <LambDashboard /> : <BeefDashboard />}
      </Stack>
    );
  }

  return <Supplier />;
};

export default Page;
