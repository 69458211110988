import { Stack } from "@mui/material";
import { LooseObject, QueryResult } from "../../../../../utils/Types";
import { DialogWrapper, Spinner } from "../../../../../components";
import ChartItem from "./ChartItem";

const ViewCharts = ({
  loading,
  chartData,
  supplierWithFullInfo,
  onCancel,
}: {
  loading: boolean;
  chartData: QueryResult | undefined;
  supplierWithFullInfo: LooseObject;
  onCancel: (values: any) => void;
}) => {
  const optimisedData: QueryResult | undefined = chartData;

  return (
    <DialogWrapper onCancel={onCancel} title="Class Summary by Carcase Weight to Yield">
      <Stack width={{ xs: "100%", md: "90vw", lg: "80vw" }}>
        {!optimisedData?.columns ? (
          <Spinner />
        ) : (
          <Stack spacing={2}>
            <ChartItem
              loading={loading}
              data={chartData}
              title="Leg Meat Yield"
              supplierWithFullInfo={supplierWithFullInfo}
              bottomOfTarget={22.75}
              xAxisName="HOTWEIGHT_KG"
              yAxisName="LEG_YIELD"
            />
            <ChartItem
              loading={loading}
              data={chartData}
              title="Loin Meat Yield"
              supplierWithFullInfo={supplierWithFullInfo}
              bottomOfTarget={15.5}
              xAxisName="HOTWEIGHT_KG"
              yAxisName="LOIN_YIELD"
            />
            <ChartItem
              loading={loading}
              data={chartData}
              title="Shoulder Meat Yield"
              supplierWithFullInfo={supplierWithFullInfo}
              bottomOfTarget={18.75}
              xAxisName="HOTWEIGHT_KG"
              yAxisName="SHLD_YIELD"
            />
          </Stack>
        )}
      </Stack>
    </DialogWrapper>
  );
};

export default ViewCharts;
