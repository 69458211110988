import * as Yup from "yup";
import validator from "validator";

import { LooseObject, MessageText, FormInputItem, PasswordLength } from "../../utils/Types";

const getValidateOptions = (fieldsWithContext: FormInputItem[]) => {
  const options: LooseObject = {};
  if (fieldsWithContext && Array.isArray(fieldsWithContext) && fieldsWithContext.length > 0) {
    const fields = fieldsWithContext.map(item => !item.avoidValidation && item.name);
    if (fields.includes("name")) {
      options.name = Yup.string().required(MessageText.INPUT_ERROR_REQUIRED);
    }
    if (fields.includes("firstName")) {
      options.firstName = Yup.string().required(MessageText.INPUT_ERROR_REQUIRED);
    }
    if (fields.includes("lastName")) {
      options.lastName = Yup.string().required(MessageText.INPUT_ERROR_REQUIRED);
    }
    if (fields.includes("email")) {
      options.email = Yup.string()
        .required(MessageText.INPUT_ERROR_REQUIRED)
        .test("isValidEmail", MessageText.INPUT_ERROR_EMAIL, v => validator.isEmail(v));
    }
    if (fields.includes("password")) {
      options.password = Yup.string()
        .required(MessageText.INPUT_ERROR_REQUIRED)
        .min(PasswordLength.MIN, `At least ${PasswordLength.MIN} characters please`)
        .max(PasswordLength.MAX, `No more than ${PasswordLength.MAX} characters please`);
    }
    if (fields.includes("oldPassword")) {
      options.oldPassword = Yup.string()
        .required(MessageText.INPUT_ERROR_REQUIRED)
        .min(PasswordLength.MIN, `At least ${PasswordLength.MIN} characters please`)
        .max(PasswordLength.MAX, `No more than ${PasswordLength.MAX} characters please`);
    }
    if (fields.includes("passwordAgain")) {
      options.passwordAgain = Yup.string()
        .required(MessageText.INPUT_ERROR_REQUIRED)
        .min(PasswordLength.MIN, `At least ${PasswordLength.MIN} characters please`)
        .max(PasswordLength.MAX, `No more than ${PasswordLength.MAX} characters please`)
        .test("isPasswordMatch", "Password does not match", function (value) {
          return this.parent.password === value;
        });
    }
    if (fields.includes("idString")) {
      options.idString = Yup.string().required(MessageText.INPUT_ERROR_REQUIRED);
    }
    if (fields.includes("zitadelOrgIdString")) {
      options.zitadelOrgIdString = Yup.string().required(MessageText.INPUT_ERROR_REQUIRED);
    }
    if (fields.includes("displayName")) {
      options.displayName = Yup.string().required(MessageText.INPUT_ERROR_REQUIRED);
    }
    if (fields.includes("country")) {
      options.country = Yup.string().required(MessageText.INPUT_ERROR_REQUIRED);
    }
    // if (fields.includes("pages")) {
    //   options.pages = Yup.string()
    //     .required(MessageText.INPUT_ERROR_REQUIRED)
    //     .test("isValidJSON", MessageText.INPUT_ERROR_JSON, v => validator.isJSON(v));
    // }
    if (fields.includes("roleKey")) {
      options.roleKey = Yup.string().required(MessageText.INPUT_ERROR_REQUIRED);
    }
    if (fields.includes("roleDescription")) {
      options.roleDescription = Yup.string().required(MessageText.INPUT_ERROR_REQUIRED);
    }
    if (fields.includes("pic")) {
      options.pic = Yup.string().required(MessageText.INPUT_ERROR_REQUIRED);
    }
    if (fields.includes("businessName")) {
      options.businessName = Yup.string().required(MessageText.INPUT_ERROR_REQUIRED);
    }
    if (fields.includes("quantity")) {
      // options.quantity = Yup.number().required(MessageText.INPUT_ERROR_REQUIRED);
      options.quantity = Yup.mixed()
        .test({
          name: "is-number",
          message: MessageText.INPUT_ERROR_NUMBER,
          test: value => {
            if (value === undefined || value === null || value === "") {
              return true; // Allow empty values, they will be caught by .required()
            }
            const number = Number(value);
            return !isNaN(number) && isFinite(number);
          },
        })
        .transform((value, originalValue) => {
          // Convert to number if it's a valid number string
          const number = Number(originalValue);
          return !isNaN(number) && isFinite(number) ? number : value;
        })
        .required(MessageText.INPUT_ERROR_REQUIRED);
    }
    if (fields.includes("status")) {
      options.status = Yup.string().required(MessageText.INPUT_ERROR_REQUIRED);
    }
    if (fields.includes("bookingDate")) {
      options.bookingDate = Yup.string().required(MessageText.INPUT_ERROR_REQUIRED);
    }
    if (fields.includes("gridPricingYear")) {
      options.gridPricingYear = Yup.number().required(MessageText.INPUT_ERROR_REQUIRED).min(2020, `Min 2020`).max(2050, `Max 2050`);
    }
    if (fields.includes("gridPricingWeek")) {
      options.gridPricingWeek = Yup.number().required(MessageText.INPUT_ERROR_REQUIRED).min(1, `Min 1`).max(52, `Max 52`);
    }
    if (fields.includes("agreedQuantity")) {
      options.agreedQuantity = Yup.number().required(MessageText.INPUT_ERROR_REQUIRED);
    }
    if (fields.includes("breed")) {
      options.breed = Yup.string().required(MessageText.INPUT_ERROR_REQUIRED);
    }
    if (fields.includes("estimatedAverageWeightKg")) {
      options.estimatedAverageWeightKg = Yup.string().required(MessageText.INPUT_ERROR_REQUIRED);
    }
    if (fields.includes("woolLengthMm")) {
      options.woolLengthMm = Yup.string().required(MessageText.INPUT_ERROR_REQUIRED);
    }
    if (fields.includes("finishingNutrition")) {
      options.finishingNutrition = Yup.string().required(MessageText.INPUT_ERROR_REQUIRED);
    }
    if (fields.includes("ageMonths")) {
      options.ageMonths = Yup.string().required(MessageText.INPUT_ERROR_REQUIRED);
    }
    if (fields.includes("isMuelsed")) {
      options.isMuelsed = Yup.string().required(MessageText.INPUT_ERROR_REQUIRED);
    }
    if (fields.includes("producerFirstName")) {
      options.producerFirstName = Yup.string().required(MessageText.INPUT_ERROR_REQUIRED);
    }
    if (fields.includes("producerLastName")) {
      options.producerLastName = Yup.string().required(MessageText.INPUT_ERROR_REQUIRED);
    }
    if (fields.includes("producerBusinessName")) {
      options.producerBusinessName = Yup.string().required(MessageText.INPUT_ERROR_REQUIRED);
    }
    if (fields.includes("isTocAgreed")) {
      options.isTocAgreed = Yup.boolean().isTrue(MessageText.INPUT_ERROR_REQUIRED);
    }
  }

  return options;
};

const getValidationSchema = (fields: FormInputItem[]) => Yup.object().shape(getValidateOptions(fields));

export default getValidationSchema;
