import { forwardRef, useState } from "react";
import { DateRange, FormInputCategory, FormInputItem } from "utils/Types";
import moment from "moment";
import { getDateRangeOptions } from "utils/Helper";
import Form from "./Form";
import { useMediaQuery, useTheme } from "@mui/material";

// Defining the array of strings
export const DATE_RANGE_OPTION_KEYS = ["1-m", "2-m", "3-m", "6-m", "12-m"] as const;

// Using a type assertion to ensure the type matches the array
export type DateRangeOptionKey = (typeof DATE_RANGE_OPTION_KEYS)[number] | "custom";

const DateRangeSelect = forwardRef(
  (
    {
      existingInputs = [],
      extraInputs = [],
      defaultValue,
      onDateSelectedChange,
      onDateRangeChange,
    }: {
      existingInputs?: FormInputItem[];
      extraInputs?: FormInputItem[];
      defaultValue?: DateRangeOptionKey;
      onDateSelectedChange?: (v: DateRangeOptionKey) => void; // for set defaultValue
      onDateRangeChange?: (v: DateRange) => void;
    },
    ref
  ) => {
    const dateRangeOptions = getDateRangeOptions(DATE_RANGE_OPTION_KEYS);
    const [dateSelected, setDateSelected] = useState<string>(defaultValue || DATE_RANGE_OPTION_KEYS[2]); // defaul to last 3 months
    const defaultDatePoint = dateRangeOptions.find(i => i.key === defaultValue)?.value;
    const defaultDateRange: DateRange = {
      from: defaultDatePoint ? moment().add(-defaultDatePoint.n, defaultDatePoint.u) : moment().add(-3, "months").startOf("day"),
      to: moment(),
    };
    const [dateRange, setDateRange] = useState<DateRange>(defaultDateRange);

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

    const handleChangeDateSelected = (v: any) => {
      setDateSelected(v);
      onDateSelectedChange?.(v);

      const datePoint = dateRangeOptions.find(i => i.key === v)?.value;

      if (datePoint) {
        const newDateRange = { from: moment().add(-datePoint.n, datePoint.u), to: moment() };
        setDateRange(newDateRange);
        onDateRangeChange?.(newDateRange);
      }
    };

    const inputs: FormInputItem[] = [
      {
        name: "dateSelected",
        category: FormInputCategory.SELECT,
        options: dateRangeOptions.map(i => ({ ...i, label: "Past " + i.label, value: i.key })).concat({ key: "custom", label: "Custom", value: "custom" }),
        defaultValue: dateSelected,
        gridLayout: { xs: 12, md: 4, lg: 3, xl: 2 },
        exposeValue: handleChangeDateSelected,
      },
    ];
    if (dateSelected === "custom") {
      inputs.push({
        name: "dateRange",
        category: FormInputCategory.DATE_RANGE_PICKER,
        disableFuture: true,
        defaultValue: { from: dateRange.from, to: dateRange.to },
        gridLayout: { xs: 12, md: 9, lg: 6, xl: 4 },
        exposeValue: v => {
          console.log(v);
        },
      });
    }

    return (
      <Form
        ref={ref}
        isHorizontal={!isSmallScreen}
        buttonText={dateSelected === "custom" ? "Update" : undefined}
        buttonGridLayout={{ xs: undefined }}
        onSubmit={v => {
          const newDateRange = { from: v.dateRangeFrom, to: v.dateRangeTo };
          setDateRange(newDateRange);
          onDateRangeChange?.(newDateRange);
        }}
        inputs={existingInputs.concat(inputs).concat(extraInputs)}
        noValidation
      />
    );
  }
);

export default DateRangeSelect;
