import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { LooseObject } from "utils/Types";

export interface UserConfigState {
  value: LooseObject;
  status: "idle" | "loading" | "failed";
}

let initialValue = {};
try {
  const initialValueString = localStorage.getItem("userConfig");
  if (initialValueString) {
    initialValue = JSON.parse(initialValueString);
  }
} catch (error: any) {
  console.log(error.message + " in localStorage userConfig");
}

const initialState: UserConfigState = {
  value: initialValue as LooseObject,
  status: "idle",
};

export const userConfigSlice = createSlice({
  name: "userConfig",
  initialState,
  reducers: {
    setUserConfig: (state, action) => {
      state.value = action.payload.payload;
    },
  },
});

export const { setUserConfig } = userConfigSlice.actions;

export const selectUserConfig = (state: RootState) => state.userConfig.value;

export default userConfigSlice.reducer;
