import Grid from "@mui/material/Unstable_Grid2";
import Tiles from "./Tiles";
import Charts from "./Charts";
import { DateFormatServer, DateRange, FormInputCategory, LooseObject, PkgName, QueryResult, QueryType } from "../../../utils/Types";
import { ContentWrapper, NoDataView, Spinner } from "../../../components";
import { useContext, useEffect, useState } from "react";
import { getCurrentLocation, getCurrentOrg, getDataFromDataPool, getDateRangeOptions, isNotEmpty, optimiseQueryResult, prepareQueryResultForTable } from "../../../utils/Helper";
import moment from "moment";
import { SnackbarContext } from "../../../utils/Contexts";
import DateRangeSelect, { DATE_RANGE_OPTION_KEYS, DateRangeOptionKey } from "../../../components/DateRangeSelect";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { selectUser } from "../../../redux/reducers/userSlice";
import { selectOrgs } from "../../../redux/reducers/orgsSlice";
import { selectDataPool } from "../../../redux/reducers/dataPoolSlice";
import _ from "lodash";
import { ALL, ALL_LOCATIONS } from "../../../utils/Constants";
import { selectZitadelOrg } from "../../../redux/reducers/zitadelOrgSlice";
import ImageAnalysis from "./ImageAnalysis";

export const EXCLUDE_COLUMNS: string[] = [];

const Page = () => {
  const snackbar = useContext(SnackbarContext);
  const [loading, setLoading] = useState(false);

  const [data, setData] = useState<QueryResult>();
  const [dateSelected, setDateSelected] = useState<DateRangeOptionKey>("6-m");
  const [dateRange, setDateRange] = useState<DateRange>();

  const user = useAppSelector(selectUser);
  const orgs = useAppSelector(selectOrgs);
  const zitadelOrg = useAppSelector(selectZitadelOrg);
  const dataPool = useAppSelector(selectDataPool);
  const dispatch = useAppDispatch();

  const currentLocation = getCurrentLocation(orgs);

  const allLocations: string[] | undefined = currentLocation === ALL_LOCATIONS ? ["Levin", "Mataura", "Pukeuri"] : [currentLocation];
  const [location, setLocation] = useState<string>(allLocations ? allLocations[0] : "");

  useEffect(() => {
    // set default date range
    const dateRangeOptions = getDateRangeOptions(DATE_RANGE_OPTION_KEYS);
    const datePoint = dateRangeOptions.find(i => i.key === dateSelected)?.value;

    if (datePoint) {
      const newDateRange = { from: moment().add(-datePoint.n, datePoint.u), to: moment() };
      setDateRange(newDateRange);
    }
  }, []);

  useEffect(() => {
    fetchData();

    // Cleanup or clear any pending debounced function calls if necessary
    return () => fetchData.cancel();
  }, [location, dateRange]);

  const fetchData = _.debounce(async () => {
    if (dateRange && moment.isMoment(dateRange.from) && moment.isMoment(dateRange.to)) {
      setLoading(true);
      await getDataFromDataPool({
        dataPool,
        params: {
          id: `Alliance_${location}`,
          type: QueryType.ORDINARY_QUERY,
          view: `MEQCAMERA_INDIVIDUAL_IMAGES('${dateRange.from.format(DateFormatServer.SHORT)}', '${dateRange.to.format(DateFormatServer.SHORT)}', 'Alliance-${location}')`,
          isFunction: true,
          pkg: PkgName.MEQ_CAMERA,
        },
        token: user.access_token,
        dispatch,
        zitadelOrg,
        snackbar,
      }).then(dataFromDataPool => {
        setData(optimiseQueryResult(dataFromDataPool));
      });
      setLoading(false);
    }
  }, 500);

  const rankedImages: LooseObject[] = [];
  const sortedImages = _.orderBy(
    data?.rows?.filter(i => isNotEmpty(i.IMF)),
    "IMF",
    "desc"
  );

  if (sortedImages.length > 0) {
    rankedImages.push(sortedImages[0]);
  }
  if (sortedImages.length > 2) {
    rankedImages.push(sortedImages[Math.floor(sortedImages.length / 2)]);
  }
  if (sortedImages.length > 1) {
    rankedImages.push(sortedImages[sortedImages.length - 1]);
  }

  return (
    <Grid container direction="column" spacing={2}>
      <Grid>
        <ContentWrapper>
          <DateRangeSelect
            existingInputs={
              currentLocation === ALL_LOCATIONS
                ? [
                    {
                      name: "location",
                      category: FormInputCategory.SELECT,
                      options: allLocations ? allLocations.map(i => ({ label: i === ALL ? _.startCase(ALL_LOCATIONS) : i, value: i })) : [],
                      defaultValue: location,
                      gridLayout: { xs: 12, md: 3, lg: 2 },
                      exposeValue: setLocation,
                    },
                  ]
                : []
            }
            defaultValue={dateSelected}
            onDateSelectedChange={setDateSelected}
            onDateRangeChange={setDateRange}
          />
        </ContentWrapper>
      </Grid>
      {!loading && data?.numRows ? (
        <Grid>
          <Tiles data={data} />
        </Grid>
      ) : undefined}
      <Grid>
        {loading || !data ? (
          <Spinner />
        ) : !data.numRows ? (
          <NoDataView />
        ) : (
          dateRange && (
            <>
              <ImageAnalysis data={prepareQueryResultForTable({ data, org: getCurrentOrg(orgs) })} rankedImages={rankedImages} />
              <Charts data={data} loading={loading} />
            </>
          )
        )}
      </Grid>
    </Grid>
  );
};

export default Page;
