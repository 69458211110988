import { CircularProgress, Stack } from "@mui/material";
import { PublicPageContainer } from "../components";
import LoginPageLayout from "./Auth/Login/LoginPageLayout";

const Page = () => (
  <PublicPageContainer>
    <LoginPageLayout
      title="Loading"
      content={
        <Stack justifyContent="center" alignItems="center" spacing={2}>
          <CircularProgress size={50} />
        </Stack>
      }
    />
  </PublicPageContainer>
);

export default Page;
