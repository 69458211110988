import { DialogContentText, Stack, Typography, Tooltip } from "@mui/material";
import { Close } from "@mui/icons-material";
import { Message } from "utils/Types";
import ServerMessage from "./ServerMessage";
import SmallButton from "./Button/SmallButton";
import RoundIconButton from "./Button/RoundIconButton";

type Props = {
  children: React.ReactNode;
  title?: string;
  description?: string;
  cancelText?: string;
  onCancel?: (values: any) => void;
  displayCancelButton?: boolean;
  confirmText?: string;
  onConfirm?: (values: any) => void;
  hideConfirmButton?: boolean;
  loading?: boolean;
  message?: Message;
  minWidth?: number | string | { xs?: number | string; sm?: number | string; md?: number | string; lg?: number | string; xl?: number | string };
  maxWidth?: number | string | { xs?: number | string; sm?: number | string; md?: number | string; lg?: number | string; xl?: number | string };
  bgcolor?: string;
  buttonsAlignment?: "flex-end" | "center" | "flex-end";
};

const DialogWrapper = ({
  title,
  description,
  children,
  cancelText,
  onCancel,
  displayCancelButton,
  confirmText,
  onConfirm,
  hideConfirmButton,
  loading,
  message,
  minWidth,
  maxWidth,
  bgcolor,
  buttonsAlignment,
}: Props) => (
  <Stack height="100%" width="100%" minWidth={minWidth || { xs: 300, md: 600 }} maxWidth={maxWidth} justifyContent="center" position="relative" overflow="inherit">
    <Stack position="relative" width="100%" bgcolor="green">
      <Tooltip title="close">
        <Stack
          sx={{
            alignSelf: "flex-end",
            zIndex: theme => theme.zIndex.drawer + 1,
            mb: -3,
            r: 0,
            position: "absolute",
          }}
        >
          <RoundIconButton
            aria-label="Close"
            onClick={onCancel}
            sx={{
              width: 26,
              height: 26,
              bgcolor: "mybg.secondary",
              "&:hover": {
                bgcolor: "mybg.secondaryHover",
              },
            }}
          >
            <Close />
          </RoundIconButton>
        </Stack>
      </Tooltip>
    </Stack>
    <Stack
      m={1}
      p={2}
      spacing={2}
      border={theme => `1px solid ${theme.palette.myborder.secondary}`}
      borderRadius={1.2}
      overflow="auto"
      bgcolor={bgcolor || "mybg.secondary"}
      height="100%"
    >
      {title && (
        <Typography variant="textxl" fontWeight="semiBold">
          {title}
        </Typography>
      )}
      <Stack spacing={2} height="100%" maxHeight="90vh" overflow="auto" position="relative">
        {description && <DialogContentText>{description}</DialogContentText>}
        {children}
        {message && message.text && <ServerMessage message={message} />}
      </Stack>
      {((displayCancelButton && onCancel) || (!hideConfirmButton && onConfirm)) && (
        <Stack direction="row" alignSelf={buttonsAlignment || "flex-end"} spacing={1} p={2}>
          {displayCancelButton && onCancel && <SmallButton title={cancelText || "Cancel"} onClick={onCancel} variant="outlined" />}
          {!hideConfirmButton && onConfirm && <SmallButton title={confirmText || "Confirm"} onClick={onConfirm} variant="contained" loading={loading} />}
        </Stack>
      )}
    </Stack>
  </Stack>
);

export default DialogWrapper;
