import { Routes as ReactRouter, Route, BrowserRouter, useLocation } from "react-router-dom";
import PageContainer from "./PageContainer";
import PrivateRoutes from "./PrivateRoutes";
import { NAV_PAGES, OTHER_PAGES } from "utils/NavItems";
import { getOrgFromOrgIdString, optimisePageTitle } from "utils/Helper";
import { useAppSelector } from "../redux/hooks";
import { selectOrgs } from "../redux/reducers/orgsSlice";
import { useContext, useEffect } from "react";
import { ClientContext } from "utils/Contexts";
import ReactGA from "react-ga4";
import { selectIsAdminView } from "../redux/reducers/isAdminViewSlice";

const AnalyticsTracker = () => {
  const location = useLocation();
  const isAdminView = useAppSelector(selectIsAdminView);

  useEffect(() => {
    ReactGA.set({ isAdminView });
    ReactGA.send({ hitType: "pageview", page: location.pathname + location.search });
  }, [location, isAdminView]);

  return null;
};

const AppRoutes = () => {
  const allRoutes = NAV_PAGES.concat(OTHER_PAGES);

  const publicRoutes = allRoutes.filter(i => i.isPublic);
  const privateRoutes = allRoutes.filter(i => !i.isPublic);

  const orgs = useAppSelector(selectOrgs);

  const clientOverides = useContext(ClientContext);
  useEffect(() => {
    if (orgs && Array.isArray(orgs)) {
      const currentOrg = orgs?.find(i => i?.isCurrent);
      if (currentOrg) {
        clientOverides.set(getOrgFromOrgIdString(currentOrg.idString));
      }
    }
  }, []);

  return (
    <ReactRouter>
      {publicRoutes.map(item => (
        <Route key={item.title} path={item.to} element={<PageContainer title={item.title} children={item.page} hideTitle={item.hideTitle} hideNav={item.hideNav} />} />
      ))}
      <Route element={<PrivateRoutes />}>
        {privateRoutes.map(item => (
          <Route
            key={item.title}
            path={item.to}
            element={<PageContainer title={optimisePageTitle(item.title)} children={item.page} hideTitle={item.hideTitle} hideNav={item.hideNav} />}
          />
        ))}
      </Route>
    </ReactRouter>
  );
};

const Routes = () => {
  const trackingId = process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID;

  if (trackingId) {
    ReactGA.initialize(trackingId);
  } else {
    console.error("GA_MEASUREMENT_ID is missing");
  }

  return (
    <BrowserRouter>
      <AnalyticsTracker />
      <AppRoutes />
    </BrowserRouter>
  );
};

export default Routes;
