import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";

import { userReducer, userConfigReducer, orgsReducer, zitadelOrgReducer, rolesReducer, dataPoolReducer, isAdminViewReducer } from "./reducers";

export const store = configureStore({
  reducer: {
    user: userReducer,
    userConfig: userConfigReducer,
    orgs: orgsReducer,
    zitadelOrg: zitadelOrgReducer,
    roles: rolesReducer,
    dataPool: dataPoolReducer,
    isAdminView: isAdminViewReducer,
  },
  middleware: getDefaultMiddleware => getDefaultMiddleware({ serializableCheck: false }),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
