import { Typography, useTheme } from "@mui/material";
import { ChartType, DateFormat, DateFormatServer, LooseObject, QueryResult } from "../../../../../utils/Types";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { Chip, ContentWrapper, Hcharts, MinimalTable } from "../../../../../components";
import moment from "moment";
import { MRT_ColumnDef, MRT_RowData } from "material-react-table";
import { avarageYield, groupDataByVsGradeForChart, groupDataByVsGradeForTable } from "./Utils";
import { isDarkMode } from "utils/Helper";

const ChartItem = ({
  loading,
  data,
  title,
  supplierWithFullInfo,
  bottomOfTarget,
  xAxisName,
  yAxisName,
}: {
  loading: boolean;
  data: QueryResult | undefined;
  title: "Leg Meat Yield" | "Loin Meat Yield" | "Shoulder Meat Yield";
  supplierWithFullInfo: LooseObject;
  bottomOfTarget: number;
  xAxisName: string;
  yAxisName: string;
}) => {
  const dataToDisplay = data?.rows;
  const xAxis = data?.columns?.find(i => i.name === xAxisName);
  const yAxis = data?.columns?.find(i => i.name === yAxisName);

  const theme = useTheme();

  const categoriesForMinimalTable = ["Y1", "Y2", "P", "T & F", "Other"];
  const groupedCarcasesByVsGrade = groupDataByVsGradeForTable(data?.rows || [], categoriesForMinimalTable);

  const minimalTableColumns: MRT_ColumnDef<MRT_RowData, any>[] = [
    {
      accessorKey: "GRADE",
      header: "Grade",
      minSize: 100,
      maxSize: 120,
      muiTableBodyCellProps: ({ row }) => {
        if (row.index >= minimalTableData.length - 2) {
          return {
            sx: {
              fontWeight: "semiBold",
              color: "mytext.tertiary",
              textOverflow: "unset",
              whiteSpace: "normal",
              wordBreak: "keep-all",
            },
          };
        }
        return {};
      },
    },
    {
      accessorKey: "CARCASES",
      header: "Carcases",
    },
    { accessorKey: "PERCENTAGE", header: "%" },
  ];
  const minimalTableData: MRT_RowData[] = categoriesForMinimalTable.map(category => ({
    GRADE: category,
    CARCASES: groupedCarcasesByVsGrade[category].total,
    PERCENTAGE: (groupedCarcasesByVsGrade[category].percentage || 0).toFixed(2),
  }));
  minimalTableData.push({
    GRADE: "Total",
    CARCASES: data?.rows?.length || 0,
    PERCENTAGE: "100.00",
  });
  minimalTableData.push({
    GRADE: "Average Yield",
    CARCASES: "",
    PERCENTAGE: avarageYield({ data, title }),
  });

  const categoriesForChart = ["Y1", "Y2", "P", "T, F & Others"];

  const SHAPE_X_RANGE = [14.5, 21.2];
  const SHAPE_Y_RANGE = [bottomOfTarget, 100];

  return (
    <ContentWrapper width="100%" spacing={2}>
      <Typography variant="textsm" fontWeight="semiBold">
        {title}
      </Typography>
      <Grid container width="100%" spacing={1}>
        <Grid>
          <Chip label={`Mob: ${supplierWithFullInfo.MOB} | Head: ${supplierWithFullInfo.head}`} size="small" />
        </Grid>
        <Grid>
          <Chip label={`${moment(supplierWithFullInfo.KILL_DATE, DateFormatServer.SHORT).format(DateFormat.SHORT)}`} size="small" />
        </Grid>
      </Grid>
      <Grid container width="100%" spacing={2}>
        <Grid xs={12} md={8}>
          <Hcharts
            loading={loading}
            type={ChartType.SCATTER_PLOT}
            title=""
            xAxis={xAxis}
            yAxis={yAxis}
            data={groupDataByVsGradeForChart(dataToDisplay, categoriesForChart)}
            hideToolButtons
            annotations={[
              {
                draggable: "",
                shapes: [
                  {
                    type: "path",
                    points: [
                      // bottom-left corner, bottom-right, top-right, top-let, back to bottom-left
                      {
                        xAxis: 0,
                        yAxis: 0,
                        x: SHAPE_X_RANGE[0],
                        y: SHAPE_Y_RANGE[0],
                      },
                      {
                        xAxis: 0,
                        yAxis: 0,
                        x: SHAPE_X_RANGE[1],
                        y: SHAPE_Y_RANGE[0],
                      },
                      {
                        xAxis: 0,
                        yAxis: 0,
                        x: SHAPE_X_RANGE[1],
                        y: SHAPE_Y_RANGE[1],
                      },
                      {
                        xAxis: 0,
                        yAxis: 0,
                        x: SHAPE_X_RANGE[0],
                        y: SHAPE_Y_RANGE[1],
                      },
                      {
                        xAxis: 0,
                        yAxis: 0,
                        x: SHAPE_X_RANGE[0],
                        y: SHAPE_Y_RANGE[0],
                      },
                    ],
                    strokeWidth: 0,
                    fill: isDarkMode(theme) ? "rgba(255, 255, 255, 0.05)" : "rgba(0, 0, 0, 0.05)", // Fill color with transparency
                  },
                ],
                zIndex: -1, // Ensure the annotation is behind the chart series
              },
            ]}
          />
        </Grid>
        <Grid xs={12} md={4}>
          <MinimalTable columns={minimalTableColumns} data={minimalTableData} />
        </Grid>
      </Grid>
    </ContentWrapper>
  );
};

export default ChartItem;
