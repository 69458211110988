import { Typography, useTheme } from "@mui/material";
import { ChartType, DateFormat, DateFormatServer, LooseObject, QueryResult } from "../../../../../../utils/Types";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { Chip, ContentWrapper, Hcharts, MinimalTable } from "../../../../../../components";
import { MRT_ColumnDef, MRT_RowData } from "material-react-table";
import { groupDataByHotWeight, HotWeightRange } from "./Utils";
import moment from "moment";
import { avoidJsFloatingPointPrecisionIssue, isDarkMode } from "utils/Helper";
import ToolButton from "components/Button/ToolButton";
import { InfoOutlined } from "@mui/icons-material";

const PerformanceChart = ({
  loading,
  data,
  averages,
  supplierWithFullInfo,
}: {
  loading: boolean;
  data: QueryResult | undefined;
  averages: LooseObject;
  supplierWithFullInfo: LooseObject;
}) => {
  const xAxis = data?.columns?.find(i => i.name === "IMF_PERCENTAGE_OF");
  const yAxis = data?.columns?.find(i => i.name === "LMY_PERCENTAGE_OF");

  const theme = useTheme();

  const hotWeightRanges: HotWeightRange[] = [
    { name: "Under 14.5kg", min: 0, max: 14.5 },
    { name: "14.5 - 17.0kg", min: 14.5, max: 17.0 },
    { name: "17.1 - 21.2kg", min: 17.0, max: 21.2 },
    { name: "Over 21.2kg", min: 21.2, max: 1000 },
  ];

  const groupedCarcasesByHotWeight = groupDataByHotWeight(data?.rows || [], hotWeightRanges);

  const SWEET_SPOT_HOTWEIGHT_RANGE = [17.1, 23];
  const carcasesInSweetSpot = data?.rows?.filter(i => (i.HOTWEIGHT_KG || 0) >= SWEET_SPOT_HOTWEIGHT_RANGE[0] && i.HOTWEIGHT_KG <= SWEET_SPOT_HOTWEIGHT_RANGE[1]) || [];
  const totalWeightInSweetSpot = carcasesInSweetSpot.reduce((acc, curr) => acc + (curr.HOTWEIGHT_KG || 0), 0);

  const minimalTableColumns: MRT_ColumnDef<MRT_RowData, any>[] = [
    {
      accessorKey: "WEIGHT",
      header: "Weight",
      minSize: 100,
      maxSize: 120,
      muiTableBodyCellProps: ({ row }) => {
        if (row.index >= minimalTableData.length - 3) {
          return {
            sx: {
              fontWeight: "semiBold",
              color: "mytext.tertiary",
              textOverflow: "unset",
              whiteSpace: "normal",
              wordBreak: "keep-all",
            },
          };
        }
        return {};
      },
    },
    {
      accessorKey: "CARCASES",
      header: "Carcases",
    },
    { accessorKey: "PERCENTAGE", header: "%" },
  ];
  const minimalTableData: MRT_RowData[] = hotWeightRanges.map(range => ({
    WEIGHT: range.name,
    CARCASES: groupedCarcasesByHotWeight[range.name].total,
    PERCENTAGE: (groupedCarcasesByHotWeight[range.name].percentage || 0).toFixed(2),
  }));
  minimalTableData.push({
    WEIGHT: "Total",
    CARCASES: data?.rows?.length || 0,
    PERCENTAGE: "100.00",
  });
  minimalTableData.push({
    WEIGHT: "Avarage IMF %",
    CARCASES: "",
    PERCENTAGE: averages.AVG_IMF_PERCENTAGE_OF || "",
  });
  const SweetboxInfoIcon = (
    <ToolButton
      title={`Sweet Spot Hot Weight: ${SWEET_SPOT_HOTWEIGHT_RANGE[0]} - ${SWEET_SPOT_HOTWEIGHT_RANGE[1]} kg`}
      icon={<InfoOutlined sx={{ height: 16, width: 16 }} />}
      buttonProps={{ sx: { height: 16, width: 16, ml: 0.5 } }}
    />
  );
  minimalTableData.push({
    WEIGHT: (
      <>
        <Typography variant="textsm" fontWeight="semiBold" color="mytext.tertiary">
          Total weight in sweet spot (kg)
        </Typography>
        {SweetboxInfoIcon}
      </>
    ),
    CARCASES: "",
    PERCENTAGE: avoidJsFloatingPointPrecisionIssue(totalWeightInSweetSpot),
  });
  minimalTableData.push({
    WEIGHT: (
      <>
        <Typography variant="textsm" fontWeight="semiBold" color="mytext.tertiary">
          Carcases in sweet spot
        </Typography>
        {SweetboxInfoIcon}
      </>
    ),
    CARCASES: "",
    PERCENTAGE: carcasesInSweetSpot.length,
  });

  const SHAPE_X_RANGE = [3, 10];
  const SHAPE_Y_RANGE = [53, 60];
  const SHAPE_X_RANGE2 = [2, 10];

  return (
    <ContentWrapper width="100%" spacing={2}>
      <Typography variant="textmd" fontWeight="semiBold">
        Summary of Carcases LMY and IMF Performance
      </Typography>
      <Grid container width="100%" spacing={1}>
        <Grid>
          <Chip label="Carcase Based" size="small" />
        </Grid>
        <Grid>
          <Chip label={`Supplier: ${supplierWithFullInfo.SUPPLIER_NO} | ${supplierWithFullInfo.fullName}`} size="small" />
        </Grid>
        <Grid>
          <Chip label={`${moment(supplierWithFullInfo.KILL_DATE, DateFormatServer.SHORT).format(DateFormat.SHORT)}`} size="small" />
        </Grid>
        <Grid>
          <Chip label={`Mob: ${supplierWithFullInfo.MOB} | Head: ${supplierWithFullInfo.head}`} size="small" />
        </Grid>
      </Grid>
      <Grid container width="100%" spacing={2}>
        <Grid xs={12} md={8}>
          <Hcharts
            loading={loading}
            type={ChartType.SCATTER_PLOT}
            title=""
            xAxis={xAxis}
            yAxis={yAxis}
            data={hotWeightRanges.map((range, index) => ({
              data: groupedCarcasesByHotWeight[range.name].items || [],
              name: range.name,
              markerSymbol: "circle",
              markerRadius: 3 + index,
            }))}
            hideToolButtons
            annotations={[
              {
                draggable: "",
                shapes: [
                  {
                    type: "path",
                    points: [
                      // bottom-left corner, bottom-right, top-right, top-let, back to bottom-left
                      {
                        xAxis: 0,
                        yAxis: 0,
                        x: SHAPE_X_RANGE[0],
                        y: SHAPE_Y_RANGE[0],
                      },
                      {
                        xAxis: 0,
                        yAxis: 0,
                        x: SHAPE_X_RANGE[1],
                        y: SHAPE_Y_RANGE[0],
                      },
                      {
                        xAxis: 0,
                        yAxis: 0,
                        x: SHAPE_X_RANGE[1],
                        y: SHAPE_Y_RANGE[1],
                      },
                      {
                        xAxis: 0,
                        yAxis: 0,
                        x: SHAPE_X_RANGE[0],
                        y: SHAPE_Y_RANGE[1],
                      },
                      {
                        xAxis: 0,
                        yAxis: 0,
                        x: SHAPE_X_RANGE[0],
                        y: SHAPE_Y_RANGE[0],
                      },
                    ],
                    strokeWidth: 0,
                    fill: isDarkMode(theme) ? "rgba(255, 255, 255, 0.05)" : "rgba(0, 0, 0, 0.05)", // Fill color with transparency
                  },
                  {
                    type: "path",
                    points: [
                      // bottom-left corner, bottom-right, top-right, top-let, back to bottom-left
                      {
                        xAxis: 0,
                        yAxis: 0,
                        x: SHAPE_X_RANGE2[0],
                        y: SHAPE_Y_RANGE[0],
                      },
                      {
                        xAxis: 0,
                        yAxis: 0,
                        x: SHAPE_X_RANGE2[1],
                        y: SHAPE_Y_RANGE[0],
                      },
                      {
                        xAxis: 0,
                        yAxis: 0,
                        x: SHAPE_X_RANGE2[1],
                        y: SHAPE_Y_RANGE[1],
                      },
                      {
                        xAxis: 0,
                        yAxis: 0,
                        x: SHAPE_X_RANGE2[0],
                        y: SHAPE_Y_RANGE[1],
                      },
                      {
                        xAxis: 0,
                        yAxis: 0,
                        x: SHAPE_X_RANGE2[0],
                        y: SHAPE_Y_RANGE[0],
                      },
                    ],
                    strokeWidth: 0,
                    fill: isDarkMode(theme) ? "rgba(255, 255, 255, 0.05)" : "rgba(0, 0, 0, 0.05)", // Fill color with transparency
                  },
                ],
                zIndex: -1, // Ensure the annotation is behind the chart series
              },
            ]}
          />
        </Grid>
        <Grid xs={12} md={4}>
          <MinimalTable columns={minimalTableColumns} data={minimalTableData} />
        </Grid>
      </Grid>
    </ContentWrapper>
  );
};

export default PerformanceChart;
