import { Chip, ContentWrapper, Hcharts } from "../../../../components";
import { ChartType, QueryResult } from "../../../../utils/Types";
import _ from "lodash";

export const EXCLUDE_COLUMNS = ["CHAIN_NO"];

const Section = ({ data, loading }: { data: QueryResult; loading: boolean }) => {
  const optionsOfXAxis = data.columns?.filter(i => !EXCLUDE_COLUMNS.includes(i.name)).filter(i => i.type === "number");

  const dataToDisplay = data?.rows;

  const allBrands = _.uniq(dataToDisplay?.map(i => i.BRAND)).sort();

  return (
    <ContentWrapper>
      <Hcharts
        loading={loading}
        type={ChartType.HISTOGRAM}
        histnorm="percent"
        title="xAxisLabel Histogram"
        titleExtra={<Chip label="Carcase Based" color="info" size="small" />}
        axisSwitch={{
          optionsOfXAxis,
        }}
        data={allBrands.map(o => ({
          data: dataToDisplay?.filter(i => i.BRAND === o) || [],
          name: o,
        }))}
        subCharts={[{ type: ChartType.HISTOGRAM }, { type: ChartType.SCATTER_PLOT, title: "xAxisLabel vs yAxisLabel", axisSwitch: { optionsOfYAxis: optionsOfXAxis } }]}
      />
    </ContentWrapper>
  );
};

export default Section;
