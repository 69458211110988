import { ImageListItem, ImageListItemBar, Stack, SxProps, Typography, useMediaQuery, useTheme } from "@mui/material";
import { LooseObject } from "../../utils/Types";
import { MRT_ColumnDef, MRT_RowData } from "material-react-table";
import Center from "../Center";
import CopyButton from "../Button/CopyButton";
import TraitListWithPopover from "./TraitListWithPopover";
import NoImagePlaceholder from "./NoImagePlaceholder";

const Tag = ({ text, containerStyle, textStyle }: { text: string; containerStyle?: SxProps; textStyle?: SxProps }) => (
  <Center sx={{ width: "fit-content", borderRadius: 1, px: 1, height: "1.5rem", bgcolor: "mybg.secondary", ...containerStyle }}>
    <Typography variant="textxs" sx={{ color: "text.secondary", ...textStyle }}>
      {text}
    </Typography>
  </Center>
);

const ImageItem = ({
  item,
  columns,
  hiddenColumnNames,
  idKey,
  dateKey,
  imgUrlKey,
  onClick,
}: {
  item: LooseObject;
  columns: MRT_ColumnDef<MRT_RowData, any>[];
  hiddenColumnNames: string[];
  idKey: string;
  dateKey: string;
  imgUrlKey: string;
  onClick?: (v: LooseObject) => void;
}) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Stack direction="row">
      <ImageListItem sx={{ position: "relative", flex: 1 }}>
        <Stack m={2} spacing={1} sx={{ position: "absolute" }}>
          <Tag text={item[dateKey]} />
        </Stack>
        {item[imgUrlKey] ? (
          <img
            onDragStart={(e: any) => e.preventDefault()} // prevent image drag
            src={"https://" + item[imgUrlKey] + process.env.REACT_APP_IMAGE_SAS_TOKEN}
            alt={`item_${item[idKey]}`}
            loading="lazy"
            onClick={() => onClick?.(item)}
            style={{ minHeight: isSmallScreen ? 220 : 500 }}
          />
        ) : (
          <NoImagePlaceholder bgcolor="rgba(0, 0, 0, 0.3)" minHeight={isSmallScreen ? 220 : 500} />
        )}
        <ImageListItemBar
          title={
            item[idKey] && (
              <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={1}>
                {item[idKey].length > 24 ? (
                  <Stack direction="row">
                    <Typography variant="textxs" noWrap>
                      {item[idKey].substring(0, 12)}
                    </Typography>
                    <Typography variant="textxs">...</Typography>
                    <Typography variant="textxs" noWrap>
                      {item[idKey].substring(item[idKey].length - 12)}
                    </Typography>
                  </Stack>
                ) : (
                  item[idKey]
                )}
                <CopyButton text={item[idKey]} color="white" size={18} sx={{ width: 23, height: 23 }} title="Copy Barcode" />
              </Stack>
            )
          }
          subtitle={<TraitListWithPopover item={item} columns={columns} hiddenColumnNames={hiddenColumnNames} />}
        />
      </ImageListItem>
    </Stack>
  );
};

export default ImageItem;
