import { useEffect, useState } from "react";
import { Box, CssBaseline, Typography, Stack, Drawer } from "@mui/material";
import { selectUser } from "../../redux/reducers/userSlice";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import {
  ADMIN,
  ALL_LOCATIONS,
  DEFAULT_BORDER_RADIUS,
  DEFAULT_SPACING,
  DEFAULT_SPACING_MOBILE,
  ORGS_HAVING_SUPPLIER_VIEW,
  PKGS_HAVING_SUPPLIER_VIEW,
  SUPER_ADMIN,
  SUPER_USER,
} from "../../utils/Constants";
import Logo from "./Logo";
import LeftSidebar from "./LeftSidebar";
import LeftSidebarMobile from "./LeftSidebarMobile";
import SwitchRole from "./SwitchRole";
import SwitchOrg from "./SwitchOrg";
import SvgIcon from "../SvgIcon";
import RoundIconButton from "../Button/RoundIconButton";
import { selectOrgs } from "../../redux/reducers/orgsSlice";
import { selectRoles } from "../../redux/reducers/rolesSlice";
import { useLocation } from "react-router-dom";
import _ from "lodash";
import { getLocationFromOrgIdString, getOrgFromOrgIdString, getShortRoleFromRole, isStringEqualIgnoreCase, optimisePageTitle } from "../../utils/Helper";
import { NAV_PAGES, NAV_SECTIONS } from "../../utils/NavItems";
import ThemeModeSwitch from "../ThemeModeSwitch";
import { NavItem, Permission, PkgName } from "../../utils/Types";
import PageId from "../../utils/PageId";
import { Footer } from "../PageContainer";
import { selectZitadelOrg } from "../../redux/reducers/zitadelOrgSlice";
import ShowGMPContact from "./ShowGMPContact";
import { setIsAdminView } from "../../redux/reducers/isAdminViewSlice";
import SwitchView from "./SwitchView";
import Center from "components/Center";

export const SIDEBAR_WIDTH = 80;

interface Props {
  title?: string;
  children?: React.ReactNode;
}

const Nav = ({ title, children }: Props) => {
  const [mobileSidebarOpen, setMobileSidebarOpen] = useState(false);
  const user = useAppSelector(selectUser);
  const orgs = useAppSelector(selectOrgs);
  const roles = useAppSelector(selectRoles);
  const zitadelOrg = useAppSelector(selectZitadelOrg);

  const location = useLocation(); // location of current page

  const currentRole = roles.find(i => i?.isCurrent);
  const currentOrg = orgs.find(i => i?.isCurrent);
  const currentLocation = getLocationFromOrgIdString(currentOrg?.idString); // location of an org

  const allNavSections = NAV_SECTIONS.filter(i => (i.name === PkgName.ADMINISTRATION ? currentRole?.role === SUPER_ADMIN : true)); // only show administration for super admin
  const AllNavPages = NAV_PAGES.filter(i => (i.category === PkgName.ADMINISTRATION ? currentRole?.role === SUPER_ADMIN : true)); // only show administration for super admin

  const currentNavSectionName = location.pathname.split("/").length > 1 ? _.startCase(location.pathname.split("/")[1]) : "";
  const currentNavSection = allNavSections.find(i => isStringEqualIgnoreCase(currentNavSectionName, _.startCase(i.name)));

  // ------------- determine NavSections to display -------------
  let permittedSections = allNavSections.filter(i => currentOrg && _.intersection(currentOrg?.pkgs, i.requiredPkgs).length > 0);

  // ------------- determine NavItems to display -------------
  // make sure currentOrg has the pkg
  const isCurrentOrgHasPkg = (navItem: NavItem) => (navItem.category && currentOrg?.pkgs.includes(navItem.category)) || false;

  // make sure permission include the pageId
  const isPermitted = ({ permission, pageId }: { permission?: Permission; pageId: PageId }): boolean => permission?.pages.map(o => o.id).includes(pageId) || false;

  // make sure location matches
  const isLocationMatched = (navItem: NavItem): boolean => (navItem.location ? currentLocation === navItem.location : true);

  let permittedPages = AllNavPages.filter(i => isPermitted({ permission: currentRole?.permission, pageId: i.id }) && isLocationMatched(i));

  if (currentRole?.role === SUPER_ADMIN || currentRole?.role === SUPER_USER) {
    permittedPages = AllNavPages.filter(i => isCurrentOrgHasPkg(i) && isLocationMatched(i));
  } else if (currentRole?.role.includes(ALL_LOCATIONS)) {
    permittedPages = AllNavPages.filter(i => isCurrentOrgHasPkg(i) && isLocationMatched(i));
  }

  if (currentRole?.role === SUPER_ADMIN || (currentRole?.role && getShortRoleFromRole(currentRole.role) === ADMIN)) {
    permittedSections = permittedSections.concat(allNavSections.filter(i => i.name === PkgName.MANAGEMENT));
    permittedPages = permittedPages.concat(AllNavPages.filter(i => i.category === PkgName.MANAGEMENT));
  }

  const isSuperUsers = currentRole && [SUPER_ADMIN, SUPER_USER].includes(currentRole.role);
  const isAllowedToSeeAdminView = (currentRole && getShortRoleFromRole(currentRole.role) === ADMIN) || isSuperUsers;
  const [isAdminViewNow, setIsAdminViewNow] = useState(isAllowedToSeeAdminView ? true : false);
  const dispatch = useAppDispatch();

  const isCurrentOrgHavingSupplierView = currentOrg && ORGS_HAVING_SUPPLIER_VIEW.includes(getOrgFromOrgIdString(currentOrg.idString));

  if (!isAdminViewNow && isCurrentOrgHavingSupplierView) {
    permittedSections = permittedSections.filter(i => PKGS_HAVING_SUPPLIER_VIEW.includes(i.name as PkgName));
  }

  useEffect(() => {
    if (isAllowedToSeeAdminView) {
      // set isAdminView initially to true in redux
      dispatch(
        setIsAdminView({
          type: "isAdminView/set",
          payload: true,
        })
      );
    }
  }, []);

  const toggleIsAdminView = () => {
    setIsAdminViewNow(!isAdminViewNow);
    dispatch(
      setIsAdminView({
        type: "isAdminView/set",
        payload: !isAdminViewNow,
      })
    );
  };

  return (
    <Box sx={{ display: "flex", flex: 1, height: "100vh" }}>
      <CssBaseline />
      <Stack display="flex" flex={1} direction="row">
        <Drawer open={mobileSidebarOpen} onClose={() => setMobileSidebarOpen(false)}>
          <LeftSidebarMobile
            permittedSections={permittedSections}
            permittedPages={permittedPages}
            user={user}
            currentOrg={currentOrg}
            orgs={orgs}
            roles={roles}
            zitadelOrg={zitadelOrg}
            closeSidebar={() => setMobileSidebarOpen(false)}
          />
        </Drawer>
        <Stack display={{ xs: "none", md: "flex" }}>
          <LeftSidebar permittedSections={permittedSections} permittedPages={permittedPages} />
        </Stack>
        <Stack sx={{ width: "100%", height: "100%" }}>
          <Stack
            direction="row"
            spacing={1}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            sx={{
              height: 36,
              bgcolor: "mybg.secondarySubtle",
              borderBottom: theme => `1px solid ${theme.palette.myborder.primary}`,
              px: { xs: DEFAULT_SPACING_MOBILE, md: DEFAULT_SPACING },
            }}
          >
            <Stack direction="row" spacing={0.5}>
              <Center direction="row" display={{ xs: "flex", md: "none" }}>
                <RoundIconButton onClick={() => setMobileSidebarOpen(!mobileSidebarOpen)}>
                  <SvgIcon name="Menu" />
                </RoundIconButton>
                <Logo size={20} />
              </Center>
              <SwitchOrg user={user} orgs={orgs} zitadelOrg={zitadelOrg} />
            </Stack>
            <Stack direction="row" spacing={1} display="flex" justifyContent="flex-end" alignItems="center">
              {isAllowedToSeeAdminView && isCurrentOrgHavingSupplierView && (
                <SwitchView isAdminViewNow={isAdminViewNow} currentOrg={currentOrg} toggleIsAdminView={toggleIsAdminView} />
              )}
              <Stack direction="row" spacing={1} alignItems="center" display={{ xs: "none", md: "flex" }}>
                <Typography variant="textxs">{user.profile.name}</Typography>
                <SwitchRole user={user} orgs={orgs} roles={roles} zitadelOrg={zitadelOrg} />
              </Stack>
              {currentOrg?.idString === "GMP" && <ShowGMPContact />}
              <ThemeModeSwitch size="small" />
            </Stack>
          </Stack>
          <Stack
            sx={{
              width: "100%",
              height: "100%",
              p: { xs: DEFAULT_SPACING_MOBILE, md: DEFAULT_SPACING },
              pb: { xs: DEFAULT_SPACING_MOBILE / 3, md: DEFAULT_SPACING / 3 },
              overflow: "auto",
            }}
            bgcolor="mybg.secondary"
            spacing={1}
          >
            {title && (
              <Stack direction="row" spacing={2} alignItems="center">
                {currentNavSection?.icon && (
                  <Stack sx={{ width: 48, height: 48, bgcolor: "primary.main", borderRadius: DEFAULT_BORDER_RADIUS, justifyContent: "center", alignItems: "center" }}>
                    <SvgIcon name={currentNavSection?.icon} color="white" />
                  </Stack>
                )}
                <Typography variant="textxl" fontWeight="semiBold">
                  {optimisePageTitle(currentNavSection?.name) || title}
                </Typography>
              </Stack>
            )}
            <Stack
              sx={{
                width: { xs: `calc(100vw - ${DEFAULT_SPACING_MOBILE * 8 * 2}px)`, md: `calc(100vw - ${SIDEBAR_WIDTH + DEFAULT_SPACING * 8 * 2}px)` },
                height: "100%",
                overflow: "auto",
                justifyContent: "space-between",
              }}
            >
              {children}
              <Footer />
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Box>
  );
};

export default Nav;
