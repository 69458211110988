import { Box, Link, Stack, Typography } from "@mui/material";
import { GlossaryItemType } from "utils/Types";

const GlossaryItem = ({ item }: { item: GlossaryItemType }) => (
  <Stack>
    {item.link ? (
      <Box>
        <Link
          href={item.link}
          target="_blank"
          variant="textmd"
          fontWeight="semiBold"
          color="text.primary"
          sx={theme => ({
            textDecoration: "none",
            borderBottom: `1px solid ${theme.palette.text.primary}`,
            ":hover": { color: "text.secondary", borderBottom: `1px solid ${theme.palette.text.secondary}` },
          })}
        >
          {item.title}
        </Link>
      </Box>
    ) : (
      <Typography fontWeight="semiBold">{item.title}</Typography>
    )}
    <Typography>{item.content}</Typography>
    {item.extra}
  </Stack>
);

export default GlossaryItem;
