import { Typography } from "@mui/material";
import { LooseObject, QueryResult } from "../../../../utils/Types";
import { Button, ContentWrapper, Dialog, MinimalTable } from "../../../../components";
import { avoidJsFloatingPointPrecisionIssue, isNotEmpty } from "../../../../utils/Helper";
import ViewCharts from "./ViewCharts";
import { useState } from "react";
import Grid from "@mui/material/Unstable_Grid2/Grid2";

const GridReport = ({ data, chartData, loading, supplierWithFullInfo }: { data?: QueryResult; chartData?: QueryResult; loading: boolean; supplierWithFullInfo: LooseObject }) => {
  const [openDialogForViewCharts, setOpenDialogForViewCharts] = useState<boolean>(false);

  const muiTableHeadCellProps = {
    sx: {
      bgcolor: "primary.main",
      fontWeight: "semiBold",
      color: "mytext.primaryOnBrand",
    },
  };

  const columns = [
    {
      accessorKey: "GRADE_NAME",
      header: "Grid Name",
      muiTableHeadCellProps,
      maxSize: 200,
    },
    {
      accessorKey: "HEAD",
      header: "Head",
      muiTableHeadCellProps,
    },
    {
      accessorKey: "HOTWEIGHT",
      header: "Hot Weight",
      muiTableHeadCellProps,
    },
  ];

  const rows = data?.rows ? [...data?.rows] : [];
  if (rows.length > 0) {
    let totalHeads = 0;
    let totalHotWeight = 0;
    rows.forEach(row => {
      totalHeads += isNotEmpty(row.HEAD) ? row.HEAD : 0;
      totalHotWeight += isNotEmpty(row.HOTWEIGHT) ? row.HOTWEIGHT : 0;
    });

    rows.sort((a, b) => a.GRADE_NAME.localeCompare(b.GRADE_NAME));

    rows.push({
      GRADE_NAME: "Total",
      HEAD: avoidJsFloatingPointPrecisionIssue(totalHeads),
      HOTWEIGHT: avoidJsFloatingPointPrecisionIssue(totalHotWeight),
    });
  }

  return (
    <ContentWrapper width="100%" maxWidth={{ xs: `calc(100vw - 42px)`, md: `calc(100vw -  146px)` }} spacing={1}>
      <Grid container spacing={2}>
        <Grid xs={12} md={10} xl={11}>
          <Typography variant="textmd" fontWeight="semiBold">
            Class Summary by Carcase Weight to Yield
          </Typography>
        </Grid>
        <Grid xs={12} md={2} xl={1} display="flex" justifyContent="flex-end">
          <Button variant="contained" title="View Charts" onClick={() => setOpenDialogForViewCharts(true)} />
        </Grid>
      </Grid>
      <MinimalTable
        loading={loading}
        columns={columns}
        data={rows}
        muiTableBodyRowProps={({ staticRowIndex }) => {
          if (staticRowIndex === rows.length - 1) {
            return {
              sx: {
                "& .MuiTableCell-root": {
                  fontWeight: "semiBold",
                },
              },
            };
          }
          return {};
        }}
      />
      <Dialog open={openDialogForViewCharts} onClose={() => setOpenDialogForViewCharts(false)} isTransparent>
        <ViewCharts loading={loading} chartData={chartData} onCancel={() => setOpenDialogForViewCharts(false)} supplierWithFullInfo={supplierWithFullInfo} />
      </Dialog>
    </ContentWrapper>
  );
};

export default GridReport;
