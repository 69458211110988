import { Options } from "highcharts";
import { ChartType, HchartsProps, SelectedChartData, Trace } from "../../../utils/Types";
import commonOptions from "./CommonOptions";
import { sampleData, isNotEmpty } from "../../../utils/Helper";

export const scatterChartOptions = (props: HchartsProps) => {
  const { type, data, xTitle, yTitle, xAxis, yAxis, axisSwitch, pointFormatExtra, traceColors, height, zoomingType, hideLegend, annotations, subCharts, updateFilter, onSelect } =
    props;

  const traces: Trace[] = data.map(i => ({ ...i }));

  const options: Options = {
    ...commonOptions(props),
    chart: {
      type: "scatter",
      height: height || 500,
      zooming: { type: zoomingType || (subCharts || updateFilter ? "xy" : undefined) }, // set type to undefined to disable zooming
      events: {
        selection: event => {
          if (onSelect) {
            let tracesWithSelectedData = [...traces];

            const xAxisFromEvent = event.xAxis ? event.xAxis[0] : null;
            const yAxisFromEvent = event.yAxis ? event.yAxis[0] : null;
            if (xAxis?.name && xAxisFromEvent) {
              tracesWithSelectedData = tracesWithSelectedData.map(i => ({
                ...i,
                data: i.data.filter(d => d[xAxis.name] >= xAxisFromEvent.min && d[xAxis.name] <= xAxisFromEvent.max),
              }));
            }
            if (yAxis?.name && yAxisFromEvent) {
              tracesWithSelectedData = tracesWithSelectedData.map(i => ({
                ...i,
                data: i.data.filter(d => d[yAxis.name] >= yAxisFromEvent.min && d[yAxis.name] <= yAxisFromEvent.max),
              }));
            }

            const subChartOptionsWithSelectedData: HchartsProps[] = [
              {
                ...props,
                data: tracesWithSelectedData,
                axisSwitch: { ...axisSwitch, defaultXAxisName: xAxis?.name, defaultYAxisName: yAxis?.name },
                subCharts: undefined,
                updateFilter: undefined,
              },
            ];
            subCharts
              ?.filter(subChart => subChart.type !== type)
              .forEach(subChart => {
                if (subChart.type === ChartType.HISTOGRAM) {
                  subChartOptionsWithSelectedData.push({
                    ...props,
                    ...subChart,
                    type: ChartType.HISTOGRAM,
                    data: tracesWithSelectedData,
                    axisSwitch: { ...axisSwitch, ...subChart.axisSwitch, defaultXAxisName: xAxis?.name, optionsOfYAxis: undefined },
                    subCharts: undefined,
                    updateFilter: undefined,
                  });
                }
              });

            onSelect(subChartOptionsWithSelectedData);
          }

          if (updateFilter) {
            const xAxisFromEvent = event.xAxis ? event.xAxis[0] : null;
            const yAxisFromEvent = event.yAxis ? event.yAxis[0] : null;
            const selectedAxises: SelectedChartData = { xAxis: xAxisFromEvent, yAxis: yAxisFromEvent };
            updateFilter(selectedAxises);
          }

          // Return false to allow default zoom behavior, or true to prevent it
          return false;
        },
      },
    },
    tooltip: {
      useHTML: true,
      pointFormat: `${xTitle || xAxis?.label || ""}: <b>{point.x}</b><br/> ${yTitle || yAxis?.label || ""}: <b>{point.y}</b>${pointFormatExtra || ""}`,
    },
    legend: {
      enabled: hideLegend !== undefined ? hideLegend : traces.length !== 1,
    },
    series: traces.map((i, index) => {
      let dataInTrace = i.data;
      if (xAxis?.name) {
        dataInTrace = dataInTrace.filter(d => isNotEmpty(d[xAxis.name]));
      }
      if (yAxis?.name) {
        dataInTrace = dataInTrace.filter(d => isNotEmpty(d[yAxis.name]));
      }
      return {
        name: i.name,
        type: "scatter",
        data: sampleData(dataInTrace).map(point => ({
          ...point,
          x: point.x !== undefined ? point.x : xAxis?.name ? point[xAxis.name] : null,
          y: point.y !== undefined ? point.y : yAxis?.name ? point[yAxis.name] : null,
        })),
        color: traceColors?.[index],
        marker: {
          symbol: i.markerSymbol,
          radius: i.markerRadius || 3,
        },
      };
    }),
    annotations,
  };

  return options;
};
