import { Stack, Typography } from "@mui/material";

import { PublicPageContainer } from "../components";
import { HelperTextWithLink } from "../components";

const Page = () => (
  <PublicPageContainer>
    <Stack spacing={3} alignItems="center" textAlign="center">
      <Typography variant="h2" fontWeight="bold" color="primary">
        &#128519; Oops!
      </Typography>
      <Typography variant="h4" fontWeight="bold" color="primary">
        404 Page Not Found
      </Typography>
      <HelperTextWithLink text="Get lost?" to="/" linkTitle="Return to home" />
    </Stack>
  </PublicPageContainer>
);

export default Page;
