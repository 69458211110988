import { AlertColor, AutocompleteOwnerState, AutocompleteRenderOptionState } from "@mui/material";
import { AxisType, Datum } from "plotly.js";
import { ReactNode } from "react";
import { BarMode, DragMode, Orientation, SelectDirection, Tick } from "../components/Chart/SharedTypes";
import { Moment } from "moment";
import PageId from "./PageId";
import { MRT_ColumnDef, MRT_RowData } from "material-react-table";
import { Action, ThunkDispatch } from "@reduxjs/toolkit";
import { UserState } from "../redux/reducers/userSlice";
import { OrgsState } from "../redux/reducers/orgsSlice";
import { RolesState } from "../redux/reducers/rolesSlice";
import { DataPoolState } from "../redux/reducers/dataPoolSlice";
import { UserManager } from "oidc-client-ts";
import { AnnotationsOptions, OptionsChartZoomingTypeValue, SelectDataObject } from "highcharts";

export enum DateFormat {
  DEFAULT = "DD MMM YYYY HH:mm:ss",
  DEFAULT_WITH_TIMEZONE = "DD MMM YYYY HH:mm:ss ([GMT] Z)",
  SHORT = "DD MMM YYYY",
  FULL = "DD MMM YYYY HH:mm:ss SSS Z",
  DAY_AND_TIME = "DD MMM HH:mm",
  DAY_AND_MONTH = "DD MMM",
  UNIX_TIMESTAMP = "x",
}

export enum DateFormatUSA {
  DEFAULT = "MMM DD YYYY HH:mm:ss",
  DEFAULT_WITH_TIMEZONE = "MMM DD YYYY HH:mm:ss ([GMT] Z)",
  SHORT = "MMM DD YYYY",
  FULL = "MMM DD YYYY HH:mm:ss SSS Z",
  DAY_AND_TIME = "MMM DD HH:mm",
  DAY_AND_MONTH = "MMM DD",
  UNIX_TIMESTAMP = "x",
}

export enum DateFormatServer {
  DEFAULT = "YYYY-MM-DD HH:mm:ss",
  DEFAULT_WITH_TIMEZONE = "YYYY-MM-DD HH:mm:ss:SSS Z",
  SHORT = "YYYY-MM-DD",
  FULL = "YYYY-MM-DD HH:mm:ss SSS Z",
  DAY_AND_TIME = "MM-DD HH:mm",
  UNIX_TIMESTAMP = "x",
  TIMESTAMP = "YYYY-MM-DD HH:mm:ss.SSS",
}

export enum PkgName {
  ADMINISTRATION = "Administration", // for super admin only
  ALLIANCE_DATA_ANALYSIS = "Alliance Data Analysis",
  MEQ_CAMERA = "MEQ Camera",
  GMP_LAMB_BOOKINGS = "GMP Lamb Bookings",
  GMP_LAMB_DATA_ANALYSIS = "GMP Lamb Data Analysis",
  MEQ_PROBE_LAMB = "MEQ Probe Lamb",
  MANAGEMENT = "Management",
}

export type NavSection = {
  name: string;
  icon: string;
  requiredPkgs?: string[];
};

export type NavItem = {
  id: PageId;
  title: string;
  to: string; // url
  page: JSX.Element;
  category?: PkgName | "Account" | "Management" | "Administration";
  location?: string; // location of the organisation
  icon?: any;
  hideTitle?: boolean;
  hideNav?: boolean;
  isPublic?: boolean;
};

export type Org =
  | {
      createdAt: Date;
      updatedAt: Date;
      idString: string;
      country: string;
      displayName: string;
      description: string;
      isTCRequired: boolean;
      pkgs: string[];
      isValid: boolean;
      isCurrent?: boolean;
    }
  | null
  | undefined;

export type ZitadelOrg =
  | {
      idString: string;
      authority: string;
      projectResourceId: string;
      reactClientId: string;
      reactRedirectUri: string;
    }
  | null
  | undefined;

export enum ZitadelOrgIdString {
  ALLIANCE = "Alliance",
  GMP = "GMP",
  MEQ = "MEQ",
  JBS = "JBS",
}

export interface ZitadelAuth {
  authorize(): Promise<void>;
  signout(): Promise<void>;
  userManager: UserManager;
}

export type Permission = {
  _id: string;
  createdAt: Date;
  updatedAt: Date;
  role: string; // role idString
  pkgs: string[]; // pkg idString
  pages: [{ id: string; components: string[] }];
  isWrite: boolean; // has write permission
  description: string;
};

export type Role =
  | {
      role: string;
      permission?: Permission;
      isCurrent?: boolean;
      allianceBeefViewAllowed?: boolean;
      allianceLambViewAllowed?: boolean;
    }
  | null
  | undefined;

export interface Message {
  readonly text: string | null;
  readonly type: AlertColor;
}

export interface FormData {
  [prop: string]: any;
}

export enum FormInputCategory {
  AUTO_COMPLETE = "AutoComplete",
  CHECK_BOX = "CheckBox",
  COMPONENT = "Component",
  DATE_PICKER = "DatePicker",
  DATE_RANGE_PICKER = "DateRangePicker",
  DATE_TIME_PICKER = "DateTimePicker",
  NUMBER_RANGE_INPUT = "NumberRangeInput",
  RADIO_GROUP = "RadioGroup",
  SELECT = "Select",
  SWITCH = "Switch",
  TEXT_FIELD = "TextField",
}

export type FormInputItem = {
  name: string;
  label?: any;
  category: FormInputCategory;
  defaultValue?: any;
  value?: any;
  placeholder?: string;
  helperText?: any;
  helperTextSx?: {};
  inputProps?: {};
  textFieldProps?: {};
  validationParams?: {};
  exposeValue?: (value: any) => void;
  component?: JSX.Element;
  type?: string; // can be password, search, number
  required?: boolean;
  multiline?: boolean;
  size?: "small" | "medium" | undefined;
  avoidValidation?: boolean;
  options?: readonly LabelValuePair[];
  disabled?: boolean;
  disablePast?: boolean;
  disableFuture?: boolean;
  multiple?: boolean;
  format?: string;
  loading?: boolean;
  selectAll?: boolean; // select all options of a select
  gridLayout?: { xs?: number; sm?: number; md?: number; lg?: number; xl?: number };
  groupBy?: (option: string | LooseObject) => string;
  getOptionLabel?: (option: string | LooseObject) => string;
  showDefaultValueWhenEmpty?: boolean;
  autoCompleteRenderOption?: (
    props: React.HTMLAttributes<HTMLLIElement>,
    option: any,
    state: AutocompleteRenderOptionState,
    ownerState: AutocompleteOwnerState<any, boolean, true, true, "div">
  ) => React.ReactNode;
  autoCompleteShowSearchIcon?: boolean;
  autoCompleteFilterOptions?: any;
  autoCompleteExposeInputValue?: any;
  autoCompleteShowInputValue?: boolean;
};

export type LabelValuePair = { label: any; value: any; description?: any; disabled?: boolean; businessName?: string };

export type LooseObject = {
  [key: string]: any;
};

export enum MessageText {
  SUCCESS = "Mission complete",
  INPUT_ERROR_REQUIRED = "Required",
  INPUT_ERROR_EMAIL = "Please type in a valid email address",
  INPUT_ERROR_JSON = "Please type in a valid json string",
  INPUT_ERROR_NUMBER = "Should be a number",
  INPUT_ERROR_POSITIVE_NUMBER = "Should be a positive number",
  NUMBER_ONLY = "Number only please",
  NO_ORG_FOUND = "No organisation found. Please contact your admin.",
  PAGE_NOT_FOUND = "Page not found.",
  NETWORK_ERROR = "Network error",
}

export enum PasswordLength {
  MIN = 4,
  MAX = 50,
}

export interface ServerRequest {
  action: string;
  params?: {};
  token?: string;
  zitadelOrgIdString?: string;
}

export interface ServerResponse {
  message: Message;
  serverData: LooseObject | LooseObject[] | null;
  statusCode?: number;
}

export enum ServerResponseStatusCode {
  SUCCESS = 700,
  FAILED = 701,
}

export type JsDataType = "string" | "number" | "date" | "datetime" | undefined;

export type QueryResultColumn = {
  name: string;
  label: string;
  type: JsDataType;
  scale: number | null;
};

export type QueryResult = {
  err?: any;
  rows?: any[];
  columns?: QueryResultColumn[];
  numRows?: number;
  numUpdatedRows?: number;
  requestId?: string;
};

export type QueryResultTable = QueryResult & { tableColumns: MRT_ColumnDef<MRT_RowData, any>[]; tableRows: MRT_RowData[] };

export enum GmpBookingStatus {
  NotApplicable = "Not Applicable",
  Scheduled = "Scheduled",
  Assigned = "Assigned",
  Completed = "Completed",
}

export enum ChartType { // refer to PlotType to assign value
  BAR_CHART = "bar",
  BOX = "box",
  SCATTER_PLOT = "scatter",
  HISTOGRAM = "histogram",
  LINE_CHART = "line",
  PIE_CHART = "pie",
  DONUT_CHART = "donut",
  VIOLIN_PLOT = "violin",
}

export type ChartTrace = Plotly.Data & { nameX?: string; nameY?: string; x?: any; y?: any; trendline?: boolean };

export type ChartData = {
  xTitle: string;
  yTitle: string;
  data: ChartTrace[];
  xCategoryOrder?:
    | "trace"
    | "category ascending"
    | "category descending"
    | "array"
    | "total ascending"
    | "total descending"
    | "min ascending"
    | "min descending"
    | "max ascending"
    | "max descending"
    | "sum ascending"
    | "sum descending"
    | "mean ascending"
    | "mean descending"
    | "median ascending"
    | "median descending";
  xCategoryArray?: any[];
  xAxisType?: AxisType;
  xTick?: Tick;
  yAxisType?: AxisType;
  yAxisRange?: any[];
  extraYaxes?: { title: string; axisType?: AxisType }[];
};

export type ChartPoint = {
  x: Datum;
  y: Datum;
};

export type ChartProps = {
  type: ChartType;
  title?: string;
  headerBeforeLoading?: ReactNode;
  header?: ReactNode;
  data: ChartData;
  loading?: boolean;
  minHeight?: number;
  updateFilter?: (v: any) => void;
  org?: Org;
  chartTraceColors?: string[];
  dragMode?: DragMode;
  selectDirection?: SelectDirection;
  style?: LooseObject;
  bgcolor?: string;
  backdrop?: ReactNode;
  handleClick?: (v: ChartPoint) => void;
  barmode?: BarMode;
  orientation?: Orientation;
  legendX?: number;
  legendY?: number;
};

export type Trace = {
  name: any;
  data: LooseObject[]; // this is an array of object
  interestedAttibutes?: string[];
  dataFormatted?: any[];
  showInLegend?: boolean;
  markerSymbol?: string;
  markerRadius?: number;
};

export type AxisSwitchProps = {
  optionsOfXAxis?: QueryResultColumn[];
  optionsOfYAxis?: QueryResultColumn[];
  defaultXAxisName?: string;
  defaultYAxisName?: string;
  exposeXAxis?: (v: QueryResultColumn | undefined) => void;
  exposeYAxis?: (v: QueryResultColumn | undefined) => void;
  handleChartStateChangeXAxis?: (v: QueryResultColumn | undefined) => void;
  handleChartStateChangeYAxis?: (v: QueryResultColumn | undefined) => void;
};

export type SelectedChartData = {
  xAxis?: SelectDataObject | null;
  yAxis?: SelectDataObject | null;
};

export type HchartsProps = {
  loading?: boolean;
  type: ChartType;
  histnorm?: "percent" | "probability" | "density" | undefined;
  stacking?: Highcharts.OptionsStackingValue;
  title: any;
  titleExtra?: JSX.Element;
  xTitle?: string;
  yTitle?: string;
  xAxis?: QueryResultColumn;
  yAxis?: QueryResultColumn;
  yAxisMin?: number;
  yAxisMax?: number;
  xAxisMax?: number;
  xAxisType?: "date" | "-" | "linear" | "log" | "category" | "multicategory";
  yAxisType?: "date" | "-" | "linear" | "log" | "category" | "multicategory";
  xCategoryOrder?:
    | "trace"
    | "category ascending"
    | "category descending"
    | "array"
    | "total ascending"
    | "total descending"
    | "min ascending"
    | "min descending"
    | "max ascending"
    | "max descending"
    | "sum ascending"
    | "sum descending"
    | "mean ascending"
    | "mean descending"
    | "median ascending"
    | "median descending";
  data: Trace[];
  xData?: any[]; // for some charts having fixed x axis
  header?: JSX.Element;
  axisSwitch?: AxisSwitchProps;
  hideAxisSwitch?: boolean;
  valueSuffix?: string;
  traceColors?: string[];
  pointFormatExtra?: string;
  height?: number | string;
  zoomingType?: OptionsChartZoomingTypeValue;
  hideLegend?: boolean;
  annotations?: Array<AnnotationsOptions>;
  subCharts?: Partial<HchartsProps>[];
  onSelect?: (chartOptionsWithSelectedData: HchartsProps[]) => void;
  updateFilter?: (v: SelectedChartData) => void;
  hideToolButtons?: boolean;
};

export enum QueryType {
  ALL_TABLES = "ALL TABLES",
  ALL_VIEWS = "All Views",
  ALL_COLUMNS = "All Columns",
  DISTINCT_VALUES_OF_COLUMN = "DISTINCT_VALUES_OF_COLUMN",
  ORDINARY_QUERY = "Ordinary Query",
  ORIGINAL_QUERY = "Original Query",
}

export type DateRange = {
  from: Moment;
  to: Moment;
};

export enum Country {
  AU = "AU",
  NZ = "NZ",
  US = "US",
}

export type DataPoolItem = {
  id: number | string; // it is a hash of all other attributes
  params: LooseObject;
  data: QueryResult;
  updatedAt: number; // milliseconds
};

export type Dispatch = ThunkDispatch<{ user: UserState; orgs: OrgsState; roles: RolesState; dataPool: DataPoolState }, undefined, Action>;

export type GmpGridPrice = {
  rowNumber: number;
  hscwCategory: string;
  lmyCategory1: number | "";
  lmyCategory2: number | "";
  lmyCategory3: number | "";
  lmyCategory4: number | "";
  lmyCategory5: number | "";
};

export type GridPricingDataType = {
  year: number;
  week: number;
  prices: GmpGridPrice[];
  createdAt?: Date;
  updatedAt?: Date;
};

export type GlossaryItemType = { title: string; content: string; link?: string; extra?: JSX.Element };
