import { Stack } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { CircularProgressIndicator, Tile, TileContentText } from "../../../components";
import { QueryResult } from "../../../utils/Types";
import _ from "lodash";
import { isNotEmpty } from "utils/Helper";

const Tiles = ({ data }: { data: QueryResult }) => {
  const avgMSA = (_.sumBy(data.rows, "MSA_MARBLING") / (data.rows?.filter(i => isNotEmpty(i.MSA_MARBLING)).length || 1)).toFixed(0);
  const avgAUS = (_.sumBy(data.rows, "AUS_MARBLING") / (data.rows?.filter(i => isNotEmpty(i.AUS_MARBLING)).length || 1)).toFixed(0);
  const avgIMF = (_.sumBy(data.rows, "IMF") / (data.rows?.filter(i => isNotEmpty(i.IMF)).length || 1)).toFixed(1);
  const avgEyeMuscleArea = (_.sumBy(data.rows, "EYE_MUSCLE_AREA") / (data.rows?.filter(i => isNotEmpty(i.EYE_MUSCLE_AREA)).length || 1)).toFixed(0);

  return (
    <Grid container spacing={2}>
      <Grid xs={12} md>
        <Tile
          title="Avg MSA Marbling"
          content={
            <Stack spacing={1}>
              <TileContentText text={isNotEmpty(avgMSA) ? `${avgMSA}` : "N/A"} />
            </Stack>
          }
        />
      </Grid>
      <Grid xs={12} md>
        <Tile
          title="Avg AUS Marbling"
          content={
            <Stack spacing={2} direction="row">
              <TileContentText text={isNotEmpty(avgAUS) ? avgAUS : "N/A"} />
            </Stack>
          }
        />
      </Grid>
      <Grid xs={12} md>
        <Tile
          title="Avg IMF"
          content={
            <Stack spacing={2} direction="row">
              <TileContentText text={isNotEmpty(avgIMF) ? `${avgIMF}%` : "N/A"} />
              <CircularProgressIndicator value={avgIMF ? Number(avgIMF) : 0} maxValue={10} size={40} borderWidth={10} />
            </Stack>
          }
        />
      </Grid>
      <Grid xs={12} md>
        <Tile
          title="Avg Eye Muscle Area"
          content={
            <Stack spacing={2} direction="row">
              <TileContentText text={isNotEmpty(avgEyeMuscleArea) ? `${avgEyeMuscleArea} cm2` : "N/A"} />
            </Stack>
          }
        />
      </Grid>
    </Grid>
  );
};

export default Tiles;
