import { LooseObject } from "../../../../../../utils/Types";

export type HotWeightRange = { name: string; min: number; max: number };

export type GroupedData = {
  [key: string]: {
    items: LooseObject[];
    total: number;
    percentage: number;
  };
};

export const groupDataByHotWeight = (data: LooseObject[], ranges: HotWeightRange[]): GroupedData => {
  const totalItems = data.length;
  const groupedData: GroupedData = {};

  // Initialize the groupedData with empty arrays
  ranges.forEach(range => {
    groupedData[range.name] = {
      items: [],
      total: 0,
      percentage: 0,
    };
  });

  // Group the data by the specified ranges
  data.forEach(item => {
    const range = ranges.find(r => item.HOTWEIGHT_KG >= r.min && item.HOTWEIGHT_KG < r.max);
    if (range) {
      groupedData[range.name].items.push(item);
      groupedData[range.name].total += 1;
    }
  });

  // Calculate the percentage for each group
  ranges.forEach(range => {
    if (groupedData[range.name].total > 0) {
      groupedData[range.name].percentage = (groupedData[range.name].total / totalItems) * 100;
    }
  });

  return groupedData;
};
