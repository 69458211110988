import { Stack } from "@mui/material";
import { styled } from "@mui/material/styles";
import { DEFAULT_BORDER_RADIUS } from "utils/Constants";

const ContentWrapper = styled(Stack)(({ theme }) => ({
  padding: 2 * 8,
  backgroundColor: theme.palette.background.default,
  border: `1px solid ${theme.palette.myborder.secondary}`,
  borderRadius: DEFAULT_BORDER_RADIUS,
  width: "100%",
}));

export default ContentWrapper;
