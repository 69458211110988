import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { Button, ContentWrapper, Dialog } from "../../../../components";
import { Stack, Typography } from "@mui/material";
import RankedImage from "./RankedImage";
import { LooseObject, QueryResultTable } from "../../../../utils/Types";
import { useState } from "react";
import AllImages from "./AllImages";

const ImageAnalysis = ({ data, rankedImages }: { data: QueryResultTable; rankedImages?: LooseObject[] }) => {
  const [openForDialogAllImages, setOpenForDialogAllImages] = useState(false);

  const isNoImages = data?.rows?.filter(i => i.AZ_URL).length === 0;

  return (
    <ContentWrapper width="100%">
      <Grid container spacing={2}>
        <Grid xs={12}>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Stack spacing={2}>
              <Typography variant="textlg" fontWeight="semiBold">
                Image Analysis
              </Typography>
            </Stack>
            {isNoImages && <Typography>No images available.</Typography>}
            <Button variant="contained" title="View All Images" onClick={() => setOpenForDialogAllImages(true)} disabled={isNoImages} sx={{ minWidth: 155 }} />
          </Stack>
        </Grid>
        {rankedImages?.map((image, index) => (
          <Grid xs={12} key={"RankedImage_" + index}>
            <RankedImage title={`${index === 0 ? "Highest" : index === 1 ? "Median" : "Lowest"} Ranked by IMF`} carcase={image} />
          </Grid>
        ))}
        <Dialog open={openForDialogAllImages} onClose={() => setOpenForDialogAllImages(false)} isTransparent fullScreen>
          <AllImages data={data} onCancel={() => setOpenForDialogAllImages(false)} />
        </Dialog>
      </Grid>
    </ContentWrapper>
  );
};

export default ImageAnalysis;
