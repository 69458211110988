import { useMemo, useState } from "react";
import { CssBaseline, useMediaQuery } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { Provider } from "react-redux";
import getCleintSpeicifTheme, { ThemeMode } from "./utils/Theme";
import { ClientContext, ThemeModeContext } from "./utils/Contexts";
import { store } from "./redux/store";
import { Routes } from "./components";

import "@fontsource/inter/100.css";
import "@fontsource/inter/200.css";
import "@fontsource/inter/300.css";
import "@fontsource/inter/400.css";
import "@fontsource/inter/500.css";
import "@fontsource/inter/600.css";
import "@fontsource/inter/700.css";
import "@fontsource/inter/800.css";
import "@fontsource/inter/900.css";

const App = () => {
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  const [mode, setMode] = useState<ThemeMode>(prefersDarkMode ? "dark" : "light");
  const themeMode = useMemo(() => ({ toggleThemeMode: () => setMode(prevMode => (prevMode === "light" ? "dark" : "light")) }), []);

  const [client, setClient] = useState<string>("");
  const currentClient = useMemo(() => ({ set: (overides: any) => setClient(overides) }), []);

  return (
    <Provider store={store}>
        <ThemeModeContext.Provider value={themeMode}>
          <ClientContext.Provider value={currentClient}>
            <ThemeProvider theme={getCleintSpeicifTheme(client, mode)}>
              <CssBaseline />
              <Routes />
            </ThemeProvider>
          </ClientContext.Provider>
        </ThemeModeContext.Provider>
    </Provider>
  );
};

export default App;
