import { Stack } from "@mui/material";
import { ReactNode } from "react";
import { Footer } from "./PageContainer";
import { DEFAULT_SPACING, DEFAULT_SPACING_MOBILE } from "utils/Constants";

const PublicPageContainer = ({ children, bgcolor }: { children: ReactNode; bgcolor?: string }) => {
  return (
    <Stack sx={{ minWidth: 360, height: "100%", pb: { xs: DEFAULT_SPACING_MOBILE / 3, md: DEFAULT_SPACING / 3 }, bgcolor }}>
      <Stack height="100%" alignItems="center">
        <Stack flex={1} display={{ xs: "none", sm: "flex" }} />
        <Stack flex={5} sx={{ p: 3, width: "100%" }}>
          {children}
        </Stack>
        <Footer />
      </Stack>
    </Stack>
  );
};

export default PublicPageContainer;
