import Grid from "@mui/material/Unstable_Grid2";
import Charts from "./Charts";
import { DateFormat, DateFormatServer, FormInputCategory, FormInputItem, LooseObject, QueryResult, QueryType } from "../../../../utils/Types";
import { ContentWrapper, Dialog, Form, NoDataView, SmallButton, Spinner, SvgIcon } from "../../../../components";
import { useContext, useEffect, useRef, useState } from "react";
import { getCurrentOrg, getDataFromDataPool, isNotEmpty, optimiseQueryResult, postToServer, prepareQueryResultForTable } from "../../../../utils/Helper";
import moment from "moment";
import { SnackbarContext } from "../../../../utils/Contexts";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { selectUser } from "../../../../redux/reducers/userSlice";
import { selectOrgs } from "../../../../redux/reducers/orgsSlice";
import { selectDataPool } from "../../../../redux/reducers/dataPoolSlice";
import _ from "lodash";
import Tiles from "./Tiles";
import { ALL_LOTS, ALL_PICS } from "../../../../utils/Constants";
import GridReport from "./GridReport";
import RawDataTable from "../../Dashboard/RawDataTable";
import { Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import { EXCLUDE_COLUMNS } from "../../Dashboard";
import { createFilterOptions } from "@mui/material/Autocomplete";
import { selectZitadelOrg } from "../../../../redux/reducers/zitadelOrgSlice";

export const getPicsWithBusinessNames = (pics: { PIC: string; LOT_REFERENCE: string; KILL_DATE: string; NUM_GL_LAMBS: number }[], picsWithBusinessNames: LooseObject[]) =>
  pics.map(i => ({ ...i, businessName: picsWithBusinessNames.find(p => p.pic === i.PIC?.trim())?.businessName }));

export const getUniqSortedAndGroupedPics = (pics: LooseObject[]) =>
  _.orderBy(
    _.uniqBy(pics, "PIC")
      .filter(i => i.businessName)
      .map(i => ({ label: i.PIC, value: i.PIC, businessName: i.businessName })) || [],
    "businessName"
  ).concat(
    _.uniqBy(pics, "PIC")
      .filter(i => !i.businessName)
      .map(i => ({ label: i.PIC, value: i.PIC, businessName: "Other" })) || []
  );

export const getLotOptions = (pics: LooseObject[] | undefined, pic: string) =>
  _.uniq(
    pics
      ?.filter(i => i.PIC.trim() === pic)
      .map(i => i.LOT_REFERENCE?.trim() || [])
      .filter(i => isNotEmpty(i))
  )
    .sort()
    .map(i => ({ label: i, value: i }));

export const getLotAndDateOptions = (pics: LooseObject[] | undefined, pic: string) =>
  _.orderBy(pics?.filter(i => i.PIC.trim() === pic) || [], "KILL_DATE", "desc").map(i => ({
    label: i.LOT_REFERENCE,
    value: i.LOT_REFERENCE,
    numberOfLambs: i.NUM_GL_LAMBS,
    date: i.KILL_DATE,
    dateFormated: moment(i.KILL_DATE, DateFormatServer.SHORT).format(DateFormat.SHORT),
  }));

export const checkIsLotIncludedinPic = (pics: LooseObject[], pic: string, lot: string) =>
  lot === ALL_LOTS
    ? true
    : pics
        ?.filter(i => (pic === ALL_PICS ? true : i.PIC.trim() === pic))
        .map(i => i.LOT_REFERENCE?.trim() || [])
        .filter(i => isNotEmpty(i))
        .includes(lot);

const Page = () => {
  const snackbar = useContext(SnackbarContext);
  const [loading, setLoading] = useState(false);
  const [loadingPics, setLoadingPics] = useState(false);

  const [data, setData] = useState<QueryResult>();
  const [allPics, setAllPics] = useState<LooseObject[]>([]);
  const [percentiles, setPercentiles] = useState<QueryResult>();
  const [averages, setAverages] = useState<QueryResult>();
  const [averagesGlobal, setAveragesGlobal] = useState<QueryResult>();
  const [gridReport, setGridReport] = useState<QueryResult>();
  const [pic, setPic] = useState<string>("");
  const [lot, setLot] = useState<string>("");
  const [date, setDate] = useState<string>("");
  const [inputs, setInputs] = useState<FormInputItem[]>([]);
  const [openDialogForRawData, setOpenDialogForRawData] = useState<boolean>(false);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  const formRef = useRef<{ resetForm: () => void }>(null);

  const handleReset = () => {
    if (formRef.current) {
      formRef.current.resetForm();
    }
  };

  const user = useAppSelector(selectUser);
  const orgs = useAppSelector(selectOrgs);
  const zitadelOrg = useAppSelector(selectZitadelOrg);
  const dataPool = useAppSelector(selectDataPool);
  const dispatch = useAppDispatch();

  useEffect(() => {
    // load all pic
    fetchAllPicsAndPercentiles();

    return () => fetchAllPicsAndPercentiles.cancel();
  }, []);

  const fetchAllPicsAndPercentiles = _.debounce(async () => {
    setLoadingPics(true);
    await postToServer({
      action: "gmp/LoadPics",
      params: {},
      token: user.access_token,
      zitadelOrgIdString: zitadelOrg?.idString,
    }).then(async response => {
      if (response.message.type === "success" && response.serverData) {
        const picsWithBusinessNames = response.serverData as LooseObject[];

        await postToServer({
          action: "gmp/GetPicsForUser",
          params: {},
          token: user.access_token,
          zitadelOrgIdString: zitadelOrg?.idString,
        }).then(async response => {
          if (response.message.type === "success" && response.serverData) {
            const picsWithBusinessName = getPicsWithBusinessNames(response.serverData as any[], picsWithBusinessNames);

            setAllPics(picsWithBusinessName);

            const sortedPics = getUniqSortedAndGroupedPics(picsWithBusinessName);

            if (sortedPics.length > 0) {
              const firstPicWithLots = picsWithBusinessName.find(i => i.PIC === sortedPics[0].value);

              if (firstPicWithLots) {
                setPic(firstPicWithLots.PIC);
                const lotAndDateOptions = getLotAndDateOptions(picsWithBusinessName, firstPicWithLots.PIC);
                if (lotAndDateOptions.length > 0) {
                  setLot(lotAndDateOptions[0].value);
                  setDate(lotAndDateOptions[0].date);
                }
              }
            }
          }
        });
      } else {
        snackbar.open(response.message);
      }
    });
    setLoadingPics(false);

    getDataFromDataPool({
      dataPool,
      params: {
        id: getCurrentOrg(orgs)?.idString,
        type: QueryType.ORDINARY_QUERY,
        view: `GMP_PRODUCER_GET_GLOBAL_PERCENTILES()`,
        isFunction: true,
      },
      token: user.access_token,
      dispatch,
      zitadelOrg,
      snackbar,
    }).then(dataFromDataPool => {
      setPercentiles(
        optimiseQueryResult({
          ...dataFromDataPool,
          columns: dataFromDataPool?.columns?.filter(i => !EXCLUDE_COLUMNS.includes(i.name)),
        })
      );
    });

    getDataFromDataPool({
      dataPool,
      params: {
        id: getCurrentOrg(orgs)?.idString,
        type: QueryType.ORDINARY_QUERY,
        view: `GMP_GET_AGG_CARCASES('2020-01-01', '${moment().format(DateFormatServer.SHORT)}', 'All', 'All')`,
        isFunction: true,
      },
      token: user.access_token,
      dispatch,
      zitadelOrg,
      snackbar,
    }).then(dataFromDataPool => {
      setAveragesGlobal(optimiseQueryResult(dataFromDataPool));
    });
  }, 500);

  const isLotIncludedInPic = checkIsLotIncludedinPic(allPics, pic, lot);

  useEffect(() => {
    if (isNotEmpty(pic) && isNotEmpty(lot) && isNotEmpty(date) && isLotIncludedInPic) {
      fetchData();
    } else {
      setData({}); // to prevent loading all the time
    }

    // Cleanup or clear any pending debounced function calls if necessary
    return () => fetchData.cancel();
  }, [pic, lot]);

  const fetchData = _.debounce(async () => {
    if (pic && lot && date) {
      setLoading(true);
      await getDataFromDataPool({
        dataPool,
        params: {
          id: getCurrentOrg(orgs)?.idString,
          type: QueryType.ORDINARY_QUERY,
          view: `GMP_GET_AGG_CARCASES('${date}', '${date}', '${pic}', '${lot}')`,
          isFunction: true,
        },
        token: user.access_token,
        dispatch,
        zitadelOrg,
        snackbar,
      }).then(dataFromDataPool => {
        setAverages(optimiseQueryResult(dataFromDataPool));
      });

      await getDataFromDataPool({
        dataPool,
        params: {
          id: getCurrentOrg(orgs)?.idString,
          type: QueryType.ORDINARY_QUERY,
          view: `GMP_GET_INDIVIDUAL_CARCASES('${date}', '${date}', '${pic}', '${lot}')`,
          isFunction: true,
        },
        token: user.access_token,
        dispatch,
        zitadelOrg,
        snackbar,
      }).then(dataFromDataPool => {
        setData(
          optimiseQueryResult({
            ...dataFromDataPool,
            columns: dataFromDataPool?.columns?.filter(i => !EXCLUDE_COLUMNS.includes(i.name)),
          })
        );
      });

      await getDataFromDataPool({
        dataPool,
        params: {
          id: getCurrentOrg(orgs)?.idString,
          type: QueryType.ORDINARY_QUERY,
          view: `GMP_PRODUCER_LOT_GRID_REPORT('${date}', '${pic}', '${lot}')`,
          isFunction: true,
        },
        token: user.access_token,
        dispatch,
        zitadelOrg,
        snackbar,
      }).then(dataFromDataPool => {
        setGridReport(optimiseQueryResult(dataFromDataPool));
      });
      setLoading(false);
    }
  }, 500);

  useEffect(() => {
    if (pic) {
      const picOptions = getUniqSortedAndGroupedPics(allPics);
      const lotAndDateOptions = getLotAndDateOptions(allPics, pic);

      if (lotAndDateOptions.length > 0) {
        handleReset();

        setLot(lotAndDateOptions[0].value);
        setDate(lotAndDateOptions[0].date);

        setInputs([
          {
            name: "pic",
            category: FormInputCategory.AUTO_COMPLETE,
            options: picOptions,
            defaultValue: picOptions.find(i => i.value === pic),
            gridLayout: { xs: 12, md: 4, lg: 3 },
            exposeValue: v => setPic(v?.value),
            loading: loadingPics,
            groupBy: (option: any) => option.businessName,
            getOptionLabel: (option: any) => option.label,
            showDefaultValueWhenEmpty: true,
            autoCompleteShowSearchIcon: true,
            autoCompleteFilterOptions: createFilterOptions({
              matchFrom: "any",
              stringify: (option: LooseObject) => option.businessName + option.label,
            }),
          },
          {
            name: "lotAndDate",
            category: FormInputCategory.AUTO_COMPLETE,
            options: lotAndDateOptions,
            defaultValue: lotAndDateOptions[0],
            gridLayout: { xs: 12, md: 5, lg: 4, xl: 3 },
            exposeValue: v => {
              setLot(v?.value);
              setDate(v?.date);
            },
            loading: loadingPics,
            groupBy: (option: any) => option.dateFormated,
            getOptionLabel: (option: any) => `${option.dateFormated} - ${option.label} (${option.numberOfLambs} Head)`,
            showDefaultValueWhenEmpty: true,
          },
        ]);
      }
    }
  }, [pic, loadingPics]);

  const avgs: LooseObject = averages?.rows && (averages?.numRows || 0) > 0 && (averages?.columns?.length || 0) > 2 ? averages?.rows?.[0] : {};
  const avgsGlobal: LooseObject = averagesGlobal?.rows && (averagesGlobal?.numRows || 0) > 0 && (averagesGlobal?.columns?.length || 0) > 2 ? averagesGlobal?.rows?.[0] : {};

  const picWithFullInfo = allPics.find(i => i.PIC.trim() === pic && i.LOT_REFERENCE === lot && i.KILL_DATE === date);

  return (
    <Grid container direction="column" spacing={2}>
      {inputs.length > 0 && (
        <Grid>
          <ContentWrapper>
            <Grid container spacing={2}>
              <Grid xs={12} md={10} xl={11}>
                <Form isHorizontal={!isSmallScreen} ref={formRef} inputs={inputs} />
              </Grid>
              <Grid xs={12} md={2} xl={1} display="flex" justifyContent="flex-end">
                <SmallButton title="Show Raw Data" sx={{ width: 125 }} onClick={() => setOpenDialogForRawData(true)} />
              </Grid>
            </Grid>
          </ContentWrapper>
        </Grid>
      )}
      {isNotEmpty(date) && moment(date, DateFormatServer.SHORT) < moment("2023-10-09", DateFormatServer.SHORT) && (
        <Grid>
          <ContentWrapper direction="row" spacing={1}>
            <SvgIcon name="AlertCircle" strokeWidth={1.67} color="#F79009" />
            <Stack>
              <Typography variant="textsm" fontWeight="bold">
                Please note that the AUS-MEAT Accredited LMY algorithm was implemented on 9 Oct 2023.
              </Typography>
              <Typography variant="textsm">All LMY data presented before that date will therefore be understated.</Typography>
            </Stack>
          </ContentWrapper>
        </Grid>
      )}
      {!loading && averages?.numRows ? (
        <Grid>
          <Tiles data={avgs} />
        </Grid>
      ) : undefined}
      <Grid>
        {loading || !data ? (
          <Spinner />
        ) : !data.numRows ? (
          <NoDataView />
        ) : (
          date &&
          picWithFullInfo && (
            <Charts
              data={data}
              pic={pic}
              lot={lot}
              date={date}
              picWithFullInfo={picWithFullInfo}
              loading={loading}
              averages={avgs}
              averagesGlobal={avgsGlobal}
              percentiles={percentiles}
            />
          )
        )}
      </Grid>
      {!loading && gridReport?.numRows ? (
        <Grid>
          <Stack>
            <GridReport data={gridReport} loading={loading} />
          </Stack>
        </Grid>
      ) : undefined}
      <Dialog open={openDialogForRawData} onClose={() => setOpenDialogForRawData(false)} isTransparent>
        <RawDataTable
          loading={loading}
          data={prepareQueryResultForTable({ data: { ...data, rows: data?.rows?.filter(i => i.LOT_REFERENCE === lot) }, org: getCurrentOrg(orgs) })}
          onCancel={() => setOpenDialogForRawData(false)}
        />
      </Dialog>
    </Grid>
  );
};

export default Page;
