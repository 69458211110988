import { Link, List, ListItem, ListItemIcon, ListItemText, Stack, Typography } from "@mui/material";
import { DialogWrapper, GlossaryItem } from "../../../components";
import { Circle } from "@mui/icons-material";
import _ from "lodash";
import { GlossaryItemType } from "../../../utils/Types";

const gmpFileFolder = "files/gmp/glossary/";

const Glossary = ({ showFactSheetsOnly, onCancel }: { showFactSheetsOnly?: boolean; onCancel: (values: any) => void }) => {
  const animalHealthItems: { title: string; link?: string }[] = [
    { title: "Arthritis / polyarthritis", link: "Gundagai_Lamb_Factsheets_Arthritis.pdf" },
    { title: "Bladder Worm", link: "Gundagai_Lamb_Factsheets_Bladder_worm.pdf" },
    { title: "Cheesey Gland", link: "Gundagai_Lamb_Factsheets_Caseous_lymphadenitis.pdf" },
    { title: "Dog Bites", link: "Gundagai_Lamb_Factsheets_Dog_bites.pdf" },
    { title: "Hydatids", link: "Gundagai_Lamb_Factsheets_Hydatids.pdf" },
    { title: "Vaccination Lesions", link: "Gundagai_Lamb_Factsheets_Vaccination_lesions.pdf" },
    { title: "Sheep Measles", link: "Gundagai_Lamb_Factsheets_Sheep_measles.pdf" },
    { title: "Liver Fluke", link: "Gundagai_Lamb_Factsheets_Liver_fluke.pdf" },
    { title: "Grass Seed Lesions", link: "Gundagai_Lamb_Factsheets_Grass_seeds.pdf" },
    { title: "Jaundice" },
    { title: "Knotty Gut", link: "Gundagai_Lamb_Factsheets_Knotty_gut.pdf" },
    { title: "Lung Worm" },
    { title: "Melanosis" },
    { title: "Nephritis", link: "Gundagai_Lamb_Factsheets_Nephritis.pdf" },
    { title: "Pneumonia", link: "Gundagai_Lamb_Factsheets_Pneumonia_pleurisy.pdf" },
    { title: "Sarcocystis", link: "Gundagai_Lamb_Factsheets_Sarcocystis.pdf" },
    { title: "Cancer" },
    { title: "Bruising" },
    { title: "Pleurisy", link: "Gundagai_Lamb_Factsheets_Pneumonia_pleurisy.pdf" },
    { title: "Fever/Septicaemia", link: "" },
    { title: "Rib Fractures", link: "Gundagai_Lamb_Factsheets_Rib_Fractures.pdf" },
    { title: "Contamination" },
  ];

  const items: GlossaryItemType[] = [
    {
      title: "Intramuscular fat (IMF %)",
      content: "Intramuscular fat is a measure of the chemical fat percentage in the loin muscle of a lamb, and the visible component is known as marbling.",
      link: "Eating_Quality_IMF.pdf",
    },
    {
      title: "Lean meat yield (LMY %)",
      content:
        "Lean meat yield is the weight of lean meat (excluding bone and fat) in a carcase, divided by the total weight of the carcase. It is generally expressed as a percentage (LMY %).",
      link: "Eating_Quality_LMY.pdf",
    },
    {
      title: "ID",
      content: "The unique identification number of the animal as assigned by Gundagai Meat Processors.",
    },
    {
      title: "Hot standard carcase weight (HSCW)",
      content:
        "Hot standard carcase weight (kg) is the hot weight of the dressed carcase that has been trimmed to the standard carcase definition that is detailed in the AUS-MEAT Language and taken within 2 hours of slaughter.",
    },
    {
      title: "Animal Health",
      content: "Animal health refers to any diseases or defects that have been detected in the carcase or offal. These can include:",
      extra: (
        <List>
          {_.orderBy(animalHealthItems, "title").map(i => (
            <ListItem key={i.title} disablePadding sx={{ pl: 2 }}>
              <ListItemIcon sx={{ minWidth: 0, marginRight: 1 }}>
                <Circle sx={{ width: 10, height: 10 }} />
              </ListItemIcon>
              <ListItemText
                primary={
                  i.link ? (
                    <Link
                      href={`${gmpFileFolder}${i.link}`}
                      target="_blank"
                      variant="textmd"
                      color="text.primary"
                      sx={theme => ({
                        textDecoration: "none",
                        borderBottom: `1px solid ${theme.palette.text.primary}`,
                        ":hover": { color: "text.secondary", borderBottom: `1px solid ${theme.palette.text.secondary}` },
                      })}
                    >
                      {i.title}
                    </Link>
                  ) : (
                    <Typography>{i.title}</Typography>
                  )
                }
              />
            </ListItem>
          ))}
        </List>
      ),
    },
    {
      title: "Grid Value",
      content: "The value of the carcase as per the pricing grid that has been agreed upon between the producer and processor. This price is reported as a per kilogram value.",
    },
    {
      title: "Total Value",
      content:
        "The total value of the carcase. The price per kilogram as agreed between the producer and processor multiplied by the hot standard carcase weight (kg) of the carcase.",
    },
    {
      title: "Lot",
      content: "This refers to the specific number that is assigned to your mob of sheep as they are processed.",
    },
    {
      title: "Time Processed",
      content: "The time the first lamb in the lot was processed.",
    },
    {
      title: "Water Use",
      content: "The amount of water in liters it takes to process one single carcase.",
    },
    {
      title: "Electricity Used",
      content: "The amount of electricity in kilowatt hour kWh it takes to process one single carcase.",
    },
    {
      title: "DEXA",
      content:
        "Dual Energy X - ray absorptiometry (DEXA) is a machine that can precisely and accurately predict the lean meat yield percentage, fat percentages and bone percentages of a carcase.",
    },
    {
      title: "Fat Score",
      content:
        "Fat score is the p[alpated fat measurement on the carcase based on the actual soft tissue depth at the GR site. The GR site is 110mm from the midline over the 12th rib. Fat score ranges from 1 (leanest) to 5 (fattest).",
    },
    {
      title: "Mec Fault",
      content:
        "A mechanical (mec) fault occurs when the plant equipment used to process lambs faults resulting in a condemned carcase. The producer is paid a mob average for that lamb.",
    },
    {
      title: "DIP",
      content: "Dead in pen",
    },
    {
      title: "DOA",
      content: "Dead on arrival",
    },
    {
      title: "Condemned",
      content: "When an entire carcase is deemed unfit for human consumption.",
    },
    {
      title: "GL Average (GL Avg.)",
      content: "Gundagai lamb average, calculated average from all lambs put through the gundagai lamb program.",
    },
    {
      title: "Count",
      content: "The number of lambs that fall into each of the categories presented on the histogram.",
    },
  ];
  return (
    <DialogWrapper onCancel={onCancel} title={showFactSheetsOnly ? "Fact Sheets" : "Glossary"}>
      <Stack spacing={2}>
        {items
          .filter(i => (showFactSheetsOnly ? i.title === "Animal Health" : true))
          .map(i => ({ ...i, link: i.link ? `${gmpFileFolder}${i.link}` : undefined }))
          .map(i => (
            <GlossaryItem key={i.title} item={i} />
          ))}
      </Stack>
    </DialogWrapper>
  );
};

export default Glossary;
