import { ExpandMore, Check } from "@mui/icons-material";
import { Stack, Typography, Popover, MenuList, MenuItem, ListItemIcon, ListItemText, Divider } from "@mui/material";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useAppDispatch } from "../../redux/hooks";
import { loadUserPermission, logout, postToServer } from "../../utils/Helper";
import SvgIcon from "../SvgIcon";
import RoundIconButton from "../Button/RoundIconButton";
import { User } from "oidc-client-ts";
import { Org, Role, ZitadelOrg } from "../../utils/Types";
import { setRoles } from "../../redux/reducers/rolesSlice";
import { SUPER_ADMIN, SUPER_USER } from "../../utils/Constants";

const SwichRole = ({ user, orgs, roles, zitadelOrg }: { user: User; orgs: Org[]; roles: Role[]; zitadelOrg: ZitadelOrg }) => {
  const dispatch = useAppDispatch();

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [roleDisplayNames, setRoleDisplayNames] = useState<{ role: string; displayName: string }[]>([]);

  useEffect(() => {
    loadRoleDisplayNames();

    return () => loadRoleDisplayNames.cancel();
  }, []);

  const loadRoleDisplayNames = _.debounce(async () => {
    await postToServer({ action: "UserRoleDisplayNames", params: {}, token: user.access_token, zitadelOrgIdString: zitadelOrg?.idString }).then(response => {
      if (response.statusCode !== 401 && response.message.type === "success" && response.serverData) {
        setRoleDisplayNames(response.serverData as any[]);
      }
    });
  }, 500);

  const currentOrg: Org = orgs.find(o => o?.isCurrent);
  const rolesFromCurrentOrg =
    roles.map(i => i?.role).includes(SUPER_ADMIN) || roles.map(i => i?.role).includes(SUPER_USER) ? roles : roles.filter(i => i?.role.startsWith(currentOrg?.idString || ""));
  const currentRole = rolesFromCurrentOrg.find(i => i?.isCurrent) || rolesFromCurrentOrg[0];

  const roleDisplayName = roleDisplayNames.find(i => i.role === currentRole?.role)?.displayName || _.startCase(currentRole?.role.split("-")[1]);

  return (
    <Stack direction="row" alignItems="center" spacing={0.5}>
      <Typography variant="textxs" fontWeight="semiBold">
        {roleDisplayName}
      </Typography>
      <Stack display={{ xs: roles.length > 1 ? "flex" : "none", md: "flex" }}>
        <RoundIconButton onClick={event => setAnchorEl(event.currentTarget)} sx={{ width: 16, height: 16 }}>
          <ExpandMore sx={{ color: "mytext.tertiary" }} />
        </RoundIconButton>
        <Popover
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={() => setAnchorEl(null)}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          sx={{ mt: 1 }}
        >
          <MenuList dense sx={{ minWidth: 200 }}>
            {currentOrg &&
              rolesFromCurrentOrg.length > 1 &&
              rolesFromCurrentOrg.map(i => (
                <MenuItem
                  key={"user_role_" + i?.role}
                  onClick={async () => {
                    if (i?.role) {
                      const permission =
                        roles.map(o => o?.role).includes(SUPER_ADMIN) || roles.map(o => o?.role).includes(SUPER_USER)
                          ? undefined
                          : await loadUserPermission(user, i?.role, zitadelOrg?.idString);
                      const updatedRoles = roles.map(o => (o?.role === i.role ? { ...o, isCurrent: true, permission } : { ...o, isCurrent: false, permission: undefined }));
                      // save user to current browser
                      localStorage.setItem("roles", JSON.stringify(updatedRoles));
                      dispatch(
                        setRoles({
                          type: "roles/set",
                          payload: updatedRoles,
                        })
                      );
                    }
                  }}
                  disabled={currentRole?.role === i?.role}
                >
                  {currentRole?.role === i?.role && (
                    <ListItemIcon>
                      <Check />
                    </ListItemIcon>
                  )}
                  <ListItemText inset={i !== currentRole}>
                    <Typography variant="textxs" fontWeight="semiBold">
                      {_.startCase(i?.role.split("-")[1])}
                    </Typography>
                  </ListItemText>
                </MenuItem>
              ))}
            {rolesFromCurrentOrg.length > 1 && <Divider />}
            <MenuItem onClick={() => logout({ dispatch, zitadelOrg })}>
              <ListItemIcon>
                <SvgIcon name="Logout" />
              </ListItemIcon>
              <ListItemText>
                <Typography variant="textxs" fontWeight="semiBold">
                  Logout
                </Typography>
              </ListItemText>
            </MenuItem>
          </MenuList>
        </Popover>
      </Stack>
    </Stack>
  );
};

export default SwichRole;
