import Grid from "@mui/material/Unstable_Grid2";
import { QueryResult } from "../../../../utils/Types";
import Histogram from "./Histogram";
import ScatterPlot from "./ScatterPlot";

const Charts = ({ loading, data }: { loading: boolean; data: QueryResult }) => {
  return (
    <Grid container spacing={2}>
      <Grid xs={12} md={6} xl={4}>
        <Histogram data={data} loading={loading} />
      </Grid>
      <Grid xs={12} md={6} xl={4}>
        <ScatterPlot data={data} loading={loading} />
      </Grid>
    </Grid>
  );
};

export default Charts;
