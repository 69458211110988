import { Stack } from "@mui/material";
import { DialogWrapper, GlossaryItem } from "../../../../components";
import { GlossaryItemType } from "../../../../utils/Types";

const Glossary = ({ showFactSheetsOnly, onCancel }: { showFactSheetsOnly?: boolean; onCancel: (values: any) => void }) => {
  const items: GlossaryItemType[] = [
    {
      title: "Intramuscular fat (IMF %)",
      content: "Intramuscular fat is a measure of the chemical fat percentage in the loin muscle of a lamb, and the visible component is known as marbling.",
    },
    {
      title: "Lean meat yield (LMY %)",
      content:
        "Lean meat yield is the weight of lean meat (excluding bone and fat) in a carcase, divided by the total weight of the carcase. It is generally expressed as a percentage (LMY %).",
    },
    {
      title: "Supplier No.",
      content: "The ID number for the Alliance supplier.",
    },
    {
      title: "Hot standard carcase weight (HSCW)",
      content:
        "Hot standard carcase weight (kg) is the hot weight of the dressed carcase that has been trimmed to the standard carcase definition that is detailed in the AUS-MEAT Language and taken within 2 hours of slaughter.",
    },
    {
      title: "Mob",
      content: "This refers to the specific number that is assigned to your mob of sheep as they are processed.",
    },
    {
      title: "Count",
      content: "The number of lambs that fall into each of the categories presented on the histogram.",
    },
  ];
  return (
    <DialogWrapper onCancel={onCancel} title={showFactSheetsOnly ? "Fact Sheets" : "Glossary"}>
      <Stack spacing={2}>
        {items
          .filter(i => (showFactSheetsOnly ? i.title === "Animal Health" : true))
          .map(i => (
            <GlossaryItem key={i.title} item={i} />
          ))}
      </Stack>
    </DialogWrapper>
  );
};

export default Glossary;
